import { Formik, Form, FormikValues, Field, ErrorMessage } from "formik";
import TextError from "../../TextError";
import * as Yup from "yup";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { RecoverPasswordStepOne } from "../../../api/rifService";
import UserContext from "../../../context/user";

const eduStoreId = 5;

const RecoveryPasswordForm = () => {
  const location = useLocation();
  const [errorRecovery, setErrorRecovery] = useState(false);

  const userContext = useContext(UserContext);
  const [searchParams] = useSearchParams();
  let email = "";

  if (searchParams.get("name")?.length > 0) {
    email = searchParams.get("name");
  }

  useEffect(() => {
    document.title = "User account | Reading Is Fundamental";
    if (location.state !== null) {
      setErrorRecovery(true);
    }
  }, [location.state]);

  const navigate = useNavigate();

  const validate = Yup.object({
    email: Yup.string()
      .required("Email adress field is required.")
      .email("The email entered is not valid."),
  });

  const handleRecoverySubmit = async (values: FormikValues) => {
    userContext.setFormProgress(50);

    RecoverPasswordStepOne(values.email.toLocaleLowerCase(), eduStoreId).then(() => {
      userContext.setFormProgress(100);
      navigate("/user", { state: { recover: true, user: values.email } });
    });
  };

  return (
    <div className="container p-4">
      <h1 className="sign-in-title">Request new password</h1>
      <div className="row p-4">
        <Formik
          initialValues={{
            email: email,
          }}
          validationSchema={validate}
          onSubmit={handleRecoverySubmit}
        >
          {({ errors }) => (
            <Form>
              <ErrorMessage
                name="email"
                component={() => (
                  <div className="error-message">
                    <TextError text={errors.email} wrapper={"div"} />
                  </div>
                )}
              />
              {errorRecovery && (
                <TextError
                  text="You have tried to use a one-time login link that has either been used or is no longer valid. Please request a new one using the form below."
                  wrapper="div"
                />
              )}
              <div className="recovery-form">
                <div className="mb-3">
                  <label
                    className="label form-recovery-label mt-4 mb-3"
                    htmlFor="email-signIn"
                  >
                    E-mail address
                    <span className="form-required"> *</span>
                  </label>
                  <Field
                    className={`form-control email-form ${
                      errors.email && "is-invalid"
                    }`}
                    type="email"
                    id="password-recovery"
                    name="email"
                  />
                </div>
                <button
                  className="button recovery-button btn-moderate-green btn-spinner-indicator mt-2"
                  type="submit"
                >
                  E-mails new password
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default RecoveryPasswordForm;
