import { ErrorMessage, Form, Formik, FormikValues } from "formik";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import TextError from "../../TextError";
import Header from "../../header/Header";
import { FormPurchaseContext } from "../../../context/Forms/purchaseContext";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import AboutPlansModal from "./Modals/AboutPlansModal";
import ModalWindow from "../../Dashboard/Common/ModalWindow";
import PromoModal from "./Modals/PromoModal";
import {
  BACK_URL,
  registerText,
  SUBSCRIPTIONS_PER_SCHOOL_PLAN,
} from "../../../utils/constants";
import GiftContext from "../../../context/Forms/giftContext";
import UserContext from "../../../context/user";
import CleverContext from "../../../context/clever";
import {
  addSubscriptions,
  cleverOrderFormPurchase,
  createTrialOrderForm,
  emailOrderForm,
  getSchoolinfo,
  orderFormPurchase,
  validateCoupon,
} from "../../../api/rifService";
import Renewals from "../../Dashboard/Admin/AdminSections/Renewals";
import { decodeValue, isCleverTheme } from "../../../utils/helpers";
import {
  addPaymentInfo,
  beginCheckout,
} from "../../../utils/events/googleAnalitycs";
import { PlanDropdowns } from "./PlanDropdowns";
import { PromoCode } from "./PromoCode";

const FormCheckout = (props: {
  form: string;
  formSteps: any;
  userInfo: string | undefined;
  onError: any;
}) => {
  const CLASSROOM_PLAN_PRICE = 179.0;
  const SCHOOL_PLAN_PRICE = 1450.0;
  const orderContext: any = useContext(FormPurchaseContext);
  const cleverContext: any = useContext(CleverContext);
  const userContext: any = useContext(UserContext);
  const { renewals } = useContext(FormPurchaseContext);
  const [discount, setDiscount] = useState(0.0);
  const giftContext: any = useContext(GiftContext);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [errorOrderForm, setErrorOrderForm] = useState(false);
  let activeStep = 3;
  let creditCardText = "CREDIT CARD";
  let orderFormPayment: boolean | undefined = false;
  let navigate = useNavigate();
  let previousPath = BACK_URL;
  let buttonText = "<< Back";
  let title = registerText[props.form].purchaseStepsText[2];

  let amountOfRenewals: number = renewals ? renewals.length : 0;

  const [promoCode, setPromoCode] = useState("");

  const isCleverSession: boolean = isCleverTheme(userContext, cleverContext);

  const handlePromoCode = (event: any) => {
    setPromoCode(event.target.value);
  };

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userContext.token}`,
    },
  };

  if (giftContext.prevUrl) {
    previousPath = giftContext.prevUrl;
    buttonText = "<< Back to Dashboard";
  }

  if (props.form === "try_it_free_purchase") {
    activeStep = 1;
    buttonText = "<< Back to Dashboard";
    previousPath = "/teachers/dashboard";
    title = registerText[props.form].purchaseStepsText[0];
  }

  if (props.form === "admin_purchase") {
    title = registerText[props.form].purchaseStepsText[0];
  }

  if (props.form === "try_it_free_purchase_email") {
    activeStep = 2;
    buttonText = "<< Back";
    previousPath = BACK_URL;
  }

  if (props.form === "gift") {
    activeStep = 2;
    buttonText = "<< Back";
    previousPath = BACK_URL;
    creditCardText = "NEXT >>";
    title = registerText[props.form].purchaseStepsText[1];
  }

  const [modal, setModal] = useState({
    show: false,
    class: "",
    onClick: () => {},
    header: <div />,
    body: <div />,
  });

  const [promoModal, setPromoModal] = useState({
    show: false,
    class: "",
    onClick: () => {},
    body: <div />,
  });

  const handleClose = () => {
    setModal({
      show: false,
      class: "",
      onClick: () => {},
      header: <div />,
      body: <div />,
    });
  };

  const handleModal = () => {
    setModal({
      show: true,
      class: "about-plans-modal p-0",
      onClick: handleClose,
      header: <h1>About Our Plans</h1>,
      body: <AboutPlansModal onClick={() => {}} />,
    });
  };

  const handleClosePromo = () => {
    setPromoModal({
      show: false,
      class: "",
      onClick: () => {},
      body: <div />,
    });
  };

  useEffect(() => {
    if (
      props.form !== "try_it_free_purchase" &&
      props.form !== "admin_purchase" &&
      props.form !== "try_it_free_purchase_email"
    ) {
      let hasOrderDetail = JSON.parse(localStorage.getItem("orderDetail"));
      if (hasOrderDetail) {
        localStorage.removeItem("orderDetail");
      }
      if (
        !orderContext.secondStep &&
        giftContext.firstStep.buyerFirstName.length === 0
      ) {
        navigate(previousPath);
      }
    }

    if (props.form === "try_it_free_purchase") {
      document.title = "Purchase From Trial | Reading Is Fundamental";
    }
  }, []);

  const handleDiscount = async (code: string, classroomPlan: string) => {
    if (code) {
      if (parseInt(classroomPlan) === 0 && amountOfRenewals === 0) {
        setPromoModal({
          show: true,
          class: "modal-promo",
          onClick: handleClosePromo,
          body: <h2>You have to select some items to buy</h2>,
        });
        setPromoCode("");
        return;
      } else {
        const response = await validateCoupon(code);
        if (response.status) {
          setDiscount(response.singlePackDiscount);
        } else {
          setDiscount(0);
          setPromoModal({
            show: true,
            class: "modal-promo",
            onClick: handleClosePromo,
            body: <h2>Your Promotion Code is not valid.</h2>,
          });
          setPromoCode("");
        }
      }
    } else {
      setDiscount(0);
      setPromoModal({
        show: true,
        class: "modal-promo",
        onClick: handleClosePromo,
        body: <h2>The Promo Code is Empty</h2>,
      });
    }
  };

  const schemaValidate = Yup.object().shape(
    {
      classroomPlan: Yup.number(),
      schoolPlan: Yup.number(),
      areEmpty: Yup.bool().when(["classroomPlan", "schoolPlan"], {
        is: (classroomPlan: number, schoolPlan: number) =>
          classroomPlan === 0 && schoolPlan === 0 && amountOfRenewals === 0,
        then: Yup.bool().required(
          props.form === "try_it_free_purchase_email" ||
            props.form === "try_it_free_purchase"
            ? "You have to select at least one Pack to Buy"
            : "You have to select some items to buy",
        ),
      }),
      promoCode: Yup.string(),
    },
    [["classroomPlan", "schoolPlan"]],
  );

  const handleSubmit = async (values: FormikValues) => {
    userContext.setFormProgress(50);
    setIsButtonDisabled(true);
    let path;
    let orderTotal =
      amountOfRenewals * 179 +
      (CLASSROOM_PLAN_PRICE * values.classroomPlan - discount) +
      SCHOOL_PLAN_PRICE * values.schoolPlan;
    if (Number(values.classroomPlan) === 0 && amountOfRenewals === 0) {
      orderTotal = orderTotal + discount;
    }

    const sendToGoogleAnalytics = () => {
      let plans = [];
      if (Number(values.classroomPlan) > 0) {
        plans.push({
          item_id: "Classroom Plan",
          item_name: "Classroom Plan",
          price: CLASSROOM_PLAN_PRICE,
          currency: "USD",
          quantity: Number(values.classroomPlan),
        });
      }

      if (Number(values.schoolPlan) > 0) {
        plans.push({
          item_id: "School Plan",
          item_name: "School Plan",
          price: SCHOOL_PLAN_PRICE,
          currency: "USD",
          quantity: Number(values.schoolPlan),
        });
      }

      if (Number(values.schoolPlan) > 0) {
        plans.push({
          item_id: "School Plan",
          item_name: "School Plan",
          price: SCHOOL_PLAN_PRICE,
          currency: "USD",
          quantity: Number(values.schoolPlan),
        });
      }

      beginCheckout(promoCode, plans, orderTotal);

      if (orderFormPayment) {
        addPaymentInfo(promoCode, plans, orderTotal);
      }
    };

    if (props.form === "purchase") {
      const password = decodeValue(orderContext.registerData.password) || null;
      path = "/purchase/checkout/card";
      orderContext.setThirdStep({
        classroomPlan: Number(values.classroomPlan),
        classroomPlanPrice: CLASSROOM_PLAN_PRICE,
        schoolPlan: Number(values.schoolPlan),
        schoolPlanPrice: SCHOOL_PLAN_PRICE,
        orderTotal: orderTotal,
        promoCode: promoCode,
        discountCode: discount,
        isOrderForm: orderFormPayment,
      });
      if (orderFormPayment) {
        setErrorOrderForm(false);
        path = "/purchase/success";
        const orderFormData = {
          school: {
            name: orderContext.secondStep.schoolName,
            street: orderContext.secondStep.street,
            countryId: orderContext.secondStep.countryId,
            stateId: orderContext.secondStep.stateId,
            city: orderContext.secondStep.city,
            zipcode: orderContext.secondStep.zipcode,
            phone: orderContext.secondStep.phone,
            typeId: orderContext.secondStep.typeId,
            isTitleOne: orderContext.secondStep.isTitleOne,
          },
          user: {
            firstName: orderContext.secondStep.firstName,
            lastName: orderContext.secondStep.lastName,
            position: orderContext.secondStep.position,
            emailAddress: orderContext.registerData.email,
            password: password,
            optIn: true,
          },
          payment: {
            lines: {
              superPackAmount: values.schoolPlan,
              singlePackAmount: values.classroomPlan,
              discountCode: promoCode,
            },
          },
        };
        const response = await orderFormPurchase(orderFormData);
        if (response.status) {
          const orderData = {
            classroomPlan: Number(values.classroomPlan),
            classroomPlanPrice: CLASSROOM_PLAN_PRICE,
            schoolPlan: Number(values.schoolPlan),
            schoolPlanPrice: SCHOOL_PLAN_PRICE,
            orderTotal: orderTotal,
            promoCode: promoCode,
            discountCode: discount,
            email: orderContext.registerData.email,
          };

          response.data.data.orderData = orderData;
          sendToGoogleAnalytics();
          navigate(path, {
            state: {
              data: response.data.data,
              orderForm: true,
              from: "change-email",
              showSuccess: true,
            },
          });
        } else {
          userContext.setFormProgress(100);
          setErrorOrderForm(true);
          setIsButtonDisabled(false);
        }
      } else {
        sendToGoogleAnalytics();
        navigate(path, { state: { ...values } });
      }
    } else if (props.form === "admin_purchase") {
      path = "/adm/checkout/card";
      orderContext.setThirdStep({
        classroomPlan: Number(values.classroomPlan),
        classroomPlanPrice: CLASSROOM_PLAN_PRICE,
        schoolPlan: Number(values.schoolPlan),
        schoolPlanPrice: SCHOOL_PLAN_PRICE,
        subscriptionsRenewals: orderContext.renewals,
        orderTotal: orderTotal,
        promoCode: promoCode,
        discountCode: discount,
        isOrderForm: orderFormPayment,
      });
      if (orderFormPayment) {
        setErrorOrderForm(false);
        path = "/adm/checkout/success";

        const renewSubscriptions = cleverContext.getRenewSubscriptions();

        let orderFormData = {
          superPackAmount: Number(values.schoolPlan),
          singlePackAmount: Number(values.classroomPlan),
          discountCode: promoCode === "" ? null : promoCode,
          method: "order_form",
          subscriptionsRenewals:
            renewSubscriptions && renewSubscriptions.length > 0
              ? renewSubscriptions.map((sub) => parseInt(sub.subId, 10))
              : [],
        };

        let response;
        if (cleverContext.isCleverUser()) {
          response = await cleverOrderFormPurchase(
            requestConfig,
            orderFormData,
          );
          localStorage.removeItem("renewSubscriptions");
        } else {
          response = await addSubscriptions(requestConfig, orderFormData);
        }
        console.log("res order", response);
        if (response && response.status) {
          console.log("res status ok");
          const orderData = {
            renewalsAmount: orderContext.renewals
              ? orderContext.renewals?.length
              : 0,
            classroomPlan: Number(values.classroomPlan),
            classroomPlanPrice: CLASSROOM_PLAN_PRICE,
            schoolPlan: Number(values.schoolPlan),
            schoolPlanPrice: SCHOOL_PLAN_PRICE,
            orderTotal: orderTotal,
            promoCode: promoCode,
            discountCode: discount,
            email: userContext.userData.emailAddress,
          };
          console.log("orderData", orderData);
          response.data.orderData = orderData;
          console.log("response data before navigate", response.data);
          navigate(path, {
            state: {
              data: response.data,
              orderForm: true,
              from: "change-email",
              order: true,
            },
          });
        } else {
          console.log("res false");
          userContext.setFormProgress(100);
          setErrorOrderForm(true);
          setIsButtonDisabled(false);
        }
      } else {
        console.log("order else");
        navigate(path, { state: { ...values } });
      }
    } else if (props.form === "try_it_free_purchase_email") {
      const password = decodeValue(orderContext.registerData.password) || null;
      path = `/purchase/from-trial/mail/${props.userInfo}/card`;
      orderContext.setThirdStep({
        classroomPlan: Number(values.classroomPlan),
        classroomPlanPrice: CLASSROOM_PLAN_PRICE,
        schoolPlan: Number(values.schoolPlan),
        schoolPlanPrice: SCHOOL_PLAN_PRICE,
        orderTotal: orderTotal,
        promoCode: promoCode,
        discountCode: discount,
        isOrderForm: orderFormPayment,
      });
      if (orderFormPayment) {
        setErrorOrderForm(false);
        path = "/purchase/success";
        let orderFormData;
        if (promoCode === "") {
          orderFormData = {
            emailInfo: props.userInfo,
            user: {
              firstName: orderContext.registerData.firstName,
              lastName: orderContext.registerData.lastName,
              emailAddress: orderContext.registerData.email,
              password: password,
              optIn: true,
            },
            payment: {
              lines: {
                superPackAmount: Number(values.schoolPlan),
                singlePackAmount: Number(values.classroomPlan),
              },
            },
          };
        } else {
          orderFormData = {
            emailInfo: props.userInfo,
            user: {
              firstName: orderContext.registerData.firstName,
              lastName: orderContext.registerData.lastName,
              emailAddress: orderContext.registerData.email,
              password: orderContext.registerData.password,
              optIn: true,
            },
            payment: {
              lines: {
                superPackAmount: values.schoolPlan,
                singlePackAmount: values.classroomPlan,
                discountCode: promoCode,
              },
            },
          };
        }

        orderContext.setThirdStep({ isOrderForm: orderFormPayment });

        const orderData = {
          classroomPlan: Number(values.classroomPlan),
          classroomPlanPrice: CLASSROOM_PLAN_PRICE,
          schoolPlan: Number(values.schoolPlan),
          schoolPlanPrice: SCHOOL_PLAN_PRICE,
          orderTotal: orderTotal,
          promoCode: promoCode,
          discountCode: discount,
          email: orderContext.registerData.email,
        };

        const response = await emailOrderForm(orderFormData);
        response.data.data.orderData = orderData;
        if (response) {
          navigate(path, {
            state: {
              data: response.data.data,
              orderForm: true,
              from: "email",
              showSuccess: true,
            },
          });
        } else {
          userContext.setFormProgress(100);
          setErrorOrderForm(true);
          setIsButtonDisabled(false);
        }
      } else {
        navigate(path, { state: { ...values } });
      }
    } else {
      let recoveredSchoolData = null;
      if (userContext.token && userContext.userData?.userId) {
        let trialAccountSchoolId = userContext.userData.edu.user.rrkSchoolId;

        recoveredSchoolData = await getSchoolinfo(
          requestConfig,
          trialAccountSchoolId,
        );
      }
      orderContext.setThirdStep({
        classroomPlan: Number(values.classroomPlan),
        classroomPlanPrice: CLASSROOM_PLAN_PRICE,
        schoolPlan: Number(values.schoolPlan),
        schoolPlanPrice: SCHOOL_PLAN_PRICE,
        orderTotal: orderTotal,
        promoCode: promoCode,
        discountCode: discount,
        isOrderForm: orderFormPayment,
      });
      if (orderFormPayment) {
        setErrorOrderForm(false);
        path = "/purchase/success";

        let orderFormData;
        if (promoCode === "") {
          orderFormData = {
            school: {
              name: recoveredSchoolData.name,
              street: recoveredSchoolData.street,
              countryId: recoveredSchoolData.countryId,
              stateId: recoveredSchoolData.stateId,
              city: recoveredSchoolData.city,
              zipcode: recoveredSchoolData.zipcode,
              phone: recoveredSchoolData.phone,
              typeId: recoveredSchoolData.typeId,
              isTitleOne: recoveredSchoolData.isTitleOne,
            },
            payment: {
              lines: {
                superPackAmount: Number(values.schoolPlan),
                singlePackAmount: Number(values.classroomPlan),
                discountCode: "0",
              },
              status: 0,
            },
          };
        } else {
          orderFormData = {
            school: {
              name: recoveredSchoolData.name,
              street: recoveredSchoolData.street,
              countryId: recoveredSchoolData.countryId,
              stateId: recoveredSchoolData.stateId,
              city: recoveredSchoolData.city,
              zipcode: recoveredSchoolData.zipcode,
              phone: recoveredSchoolData.phone,
              typeId: recoveredSchoolData.typeId,
              isTitleOne: recoveredSchoolData.isTitleOne,
            },
            payment: {
              lines: {
                superPackAmount: values.schoolPlan,
                singlePackAmount: values.classroomPlan,
                discountCode: promoCode,
              },
              status: 0,
            },
          };
        }

        let response = await createTrialOrderForm(requestConfig, orderFormData);
        if (response.status) {
          const orderData = {
            classroomPlan: Number(values.classroomPlan),
            classroomPlanPrice: CLASSROOM_PLAN_PRICE,
            schoolPlan: Number(values.schoolPlan),
            schoolPlanPrice: SCHOOL_PLAN_PRICE,
            orderTotal: orderTotal,
            promoCode: promoCode,
            discountCode: discount,
            email: userContext.userData.emailAddress,
          };
          orderContext.setThirdStep({ isOrderForm: orderFormPayment });
          response.data.orderData = orderData;
          navigate(path, {
            state: {
              data: response.data,
              orderForm: true,
              from: "change-email",
              email: userContext.userData.emailAddress,
              upgradeTrial: true,
              showSuccess: true,
            },
          });
        } else {
          userContext.setFormProgress(100);
          setErrorOrderForm(true);
          setIsButtonDisabled(false);
        }
      } else {
        giftContext.setForms({
          values: values,
          form: 2,
          orderTotal: orderTotal,
          promoCode: promoCode,
          discountCode: discount,
        });
        if (props.form === "gift") {
          sendToGoogleAnalytics();
          if (isCleverTheme(userContext, cleverContext)) {
            navigate("/teachers/gift/checkout/payment", {
              state: { ...values },
            });
          } else {
            navigate("/gift/checkout/payment", { state: { ...values } });
          }
        } else {
          navigate("/purchase/from-trial/card", { state: { ...values } });
        }
      }
    }

    userContext.setFormProgress(100);
  };

  return (
    <>
      {props.form !== "admin_purchase" && (
        <Header
          steps={props.formSteps}
          activeStep={activeStep}
          previousPath={previousPath}
          buttonText={buttonText}
        />
      )}
      <div
        className={`register-form mx-auto ${props.form !== "admin_purchase" ? "last-steps-form" : ""}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className={`login-form form-mode-${props.form}`}>
                <Formik
                  initialValues={{
                    classroomPlan: 0,
                    schoolPlan: 0,
                    promoCode: "",
                    areEmpty: "",
                  }}
                  validationSchema={schemaValidate}
                  onSubmit={handleSubmit}
                  validateOnBlur={false}
                  validateOnChange={false}
                >
                  {({ errors, touched, values }) => {
                    if (
                      errors.areEmpty !== undefined &&
                      props.form === "admin_purchase"
                    ) {
                      props.onError(true);
                    }
                    let classroomPlansAmount: number =
                      Number(values.classroomPlan) +
                      Number(values.schoolPlan) *
                        SUBSCRIPTIONS_PER_SCHOOL_PLAN +
                      amountOfRenewals;

                    return (
                      <Form style={{ textAlign: "center" }}>
                        <h1 className="step-title text-center">{title}</h1>
                        {props.form !== "admin_purchase" &&
                        Object.keys(errors).length > 0 ? (
                          <div className="errors">
                            <div className="col-1 p-0 error-logo" />
                            <ul className="p-0 errors-list checkout-error">
                              <ErrorMessage
                                name=""
                                component={() => (
                                  <TextError
                                    wrapper="li"
                                    text={errors.areEmpty}
                                  />
                                )}
                              />
                            </ul>
                          </div>
                        ) : null}

                        {!isCleverSession && (
                          <div className="step-info text-center checkout-description">
                            {registerText[props.form].planDescription}
                          </div>
                        )}
                        <h1 className="order">Order detail</h1>
                        {amountOfRenewals > 0 && <Renewals remove={true} />}
                        {props.form === "admin_purchase" && (
                          <h1 className="extra-plans mx-4 mb-4">Extra Plans</h1>
                        )}
                        {!isCleverSession && (
                          <div className="about-our-plans text-center">
                            <Link
                              to=""
                              className=""
                              onClick={() => handleModal()}
                            >
                              About our plans
                            </Link>
                          </div>
                        )}
                        <PlanDropdowns
                          isAdminPurchase={props.form === "admin_purchase"}
                          isCleverThemed={isCleverSession}
                          isButtonDisabled={isButtonDisabled}
                          errors={errors}
                          touched={touched}
                        />
                        <span className="centered-subscription-counter">
                          You have {classroomPlansAmount} classroom{" "}
                          {classroomPlansAmount === 1 ? "plan" : "plans"} in
                          your shopping cart.
                        </span>
                        <PromoCode
                          fieldValue={promoCode}
                          isCleverUser={isCleverSession}
                          isAdminPurchase={props.form === "admin_purchase"}
                          isButtonDisabled={isButtonDisabled}
                          handlePromoCode={handlePromoCode}
                          handleDiscount={() => {
                            handleDiscount(
                              promoCode,
                              values.classroomPlan.toString(),
                            );
                          }}
                        />
                        <div
                          className={`step-info ${isCleverSession ? "text-end" : "text-center"} promo-info`}
                        >
                          Promo Codes can only be applied to the purchase of a
                          single Classroom Plan.
                        </div>
                        <div></div>
                        <div className="purchase-total mx-auto">
                          <div className="single_pack">
                            Classroom Plan(s)
                            <span className="total">
                              $
                              {(
                                (CLASSROOM_PLAN_PRICE *
                                  values.classroomPlan *
                                  100) /
                                100
                              ).toFixed(2)}
                            </span>
                          </div>
                          <div className="super_pack">
                            School Plan(s)
                            <span className="total">
                              $
                              {(
                                (SCHOOL_PLAN_PRICE * values.schoolPlan * 100) /
                                100
                              ).toFixed(2)}
                            </span>
                          </div>
                          <div className="discount">
                            Discount
                            <span className="total">
                              $
                              {Number(values.classroomPlan) === 0 &&
                              amountOfRenewals === 0
                                ? "0.00"
                                : ((discount * 100) / 100).toFixed(2)}
                            </span>
                          </div>
                          <div
                            className={`order-total ${
                              props.form === "admin_purchase"
                                ? "order-total-admin"
                                : ""
                            }`}
                          >
                            Order Total
                            <span className="total">
                              $
                              {Number(values.classroomPlan) === 0 &&
                              Number(values.schoolPlan) === 0 &&
                              amountOfRenewals === 0
                                ? "0.00"
                                : Number(values.classroomPlan) === 0 &&
                                    amountOfRenewals === 0
                                  ? (
                                      amountOfRenewals * 179 +
                                      SCHOOL_PLAN_PRICE * values.schoolPlan
                                    ).toFixed(2)
                                  : (
                                      amountOfRenewals * 179 +
                                      (CLASSROOM_PLAN_PRICE *
                                        values.classroomPlan -
                                        discount) +
                                      SCHOOL_PLAN_PRICE * values.schoolPlan
                                    ).toFixed(2)}
                            </span>
                          </div>
                        </div>
                        {errorOrderForm && (
                          <TextError
                            wrapper="div"
                            text="There was an error, We were not able to create your Order Form, please try again"
                          />
                        )}
                        {props.form !== "gift" && (
                          <h1 className="order how-to-pay">
                            {isCleverSession ? "Payment" : "How to Pay"}
                          </h1>
                        )}
                        <div
                          className={`btn-payment-wrapper d-flex justify-content-center col-12 mx-auto mb-0 ${
                            props.form === "gift" && "mt-5"
                          }`}
                        >
                          <button
                            className={`btn ${isCleverSession ? "btn-table-moderate-green me-3" : "btn-submit"} payment-type`}
                            type="submit"
                            onClick={(e) => {
                              e.target.classList.add("btn-spinner-indicator");
                              orderFormPayment = false;
                            }}
                            disabled={isButtonDisabled}
                          >
                            {creditCardText}
                          </button>
                          {props.form !== "gift" && (
                            <button
                              disabled={isButtonDisabled}
                              className={`btn ${isCleverSession ? "btn-table-moderate-green " : "btn-submit btn-brandy-punch "} payment-type`}
                              type="submit"
                              onClick={(e) => {
                                e.target.classList.add("btn-spinner-indicator");
                                orderFormPayment = true;
                              }}
                            >
                              ORDER FORM
                            </button>
                          )}
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
                <ModalWindow
                  show={modal.show}
                  class={modal.class}
                  onClick={modal.onClick}
                  header={modal.header}
                  body={modal.body}
                />
                <PromoModal
                  show={promoModal.show}
                  class={promoModal.class}
                  onClick={promoModal.onClick}
                  body={promoModal.body}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormCheckout;
