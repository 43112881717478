import React from 'react'
import { Field } from "formik";

interface PromoCodeProps {
  fieldValue: string;
  isCleverUser: boolean;
  isAdminPurchase: boolean;
  isButtonDisabled: boolean;
  handlePromoCode: (event: any) => void;
  handleDiscount: () => void;
}

export const PromoCode: React.FC<PromoCodeProps> = ({
  isCleverUser,
  isAdminPurchase,
  isButtonDisabled,
  handlePromoCode,
  handleDiscount,
  fieldValue,
}) => {

  if (isCleverUser) {
    return (
      <span className="d-flex justify-content-between my-3" style={{
        marginLeft: "3.4rem",
        marginRight: "3.4rem"
      }}>
        <label
          className="form-label col-form-label p-0"
          htmlFor="promoCode"
        >
          Promo Code
        </label>
        <div>
          <Field
            className="form-control promo"
            type="text"
            name="promoCode"
            onChange={handlePromoCode}
            value={fieldValue}
            disabled={isButtonDisabled}
          />
          <button
            className={`btn rounded-1 btn-sky btn-moderate-green mt-0 btn-promo`}
            type="button"
            onClick={handleDiscount}
            disabled={isButtonDisabled}
          >
            APPLY CODE
          </button>
        </div>
      </span>
    )
  } else {
    return (
      <span className={`${isAdminPurchase ? "mx-4" : "admin-purchase-field"} d-flex justify-content-between my-3`}>
        <label
          className={`form-label col-form-label p-0`}
          htmlFor="promoCode"
        >
          Promo Code
        </label>
        <Field
          className="form-control promo promo-classic"
          type="text"
          name="promoCode"
          onChange={handlePromoCode}
          value={fieldValue}
          disabled={isButtonDisabled}
        />
        <button
          className={`btn rounded-1 btn-submit mt-0 btn-promo`}
          type="button"
          onClick={handleDiscount}
          disabled={isButtonDisabled}
        >
          APPLY CODE
        </button>
      </span>
    )
  }
}
