import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getSchoolSubscriptions,
  migrateTrialConfirm,
  userGetMe,
} from "../../../api/rifService";
import UserContext from "../../../context/user";
import Footer from "../../Footer/Footer";

const MigrateConfirm = () => {
  const userContext : any = useContext(UserContext);
  const navigate = useNavigate();
  const [adminError, setAdminError] = useState({
    adminError: false,
    adminErrorMessage: ""
  });
  const [adminInfo, setAdminInfo] = useState({
    adminName: "",
    schoolName: "",
  });

  useEffect(() => {
    if (userContext.userData.edu.user.migrationRequest !== null) {
      setAdminInfo({
        adminName: `${userContext.userData.edu.user.migrationRequest.adminInfo.firstName} ${userContext.userData.edu.user.migrationRequest.adminInfo.lastName}`,
        schoolName:
          userContext.userData.edu.user.migrationRequest.schoolInfo.name,
      });
    }
  }, []);

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userContext.token}`,
    },
  };

  const [optionSelected, setOptionSelected] = useState({
    disable: true,
    option: "",
  });

  const handleSubmit = async () => {
    let choice = "confirm";
    userContext.setFormProgress(50);

    if (optionSelected.option === "no") {
      choice = "cancel";
    }

    const response = await migrateTrialConfirm(choice, requestConfig);
    const responseMe = await userGetMe(userContext.token);

    if (response.status) {
      if (optionSelected.option === "yes") {
        userContext.setIsTrialUser(false);
      }

      const schoolId = responseMe.data.edu.user.rrkSchoolId;

      getSchoolSubscriptions(
        requestConfig,
        schoolId,
        responseMe.data.edu.user.rrkEduUserId
      ).then((schoolSubscription) => {
        console.log('get sub 1')
        userContext.saveSubscriptions(schoolSubscription, userContext.userData.edu.user);
      });

      localStorage.setItem("user", JSON.stringify(responseMe.data));
      userContext.setUserData(responseMe.data);
    } else {
      setAdminError({
        adminError: true,
        adminErrorMessage: response.data.response.data.errorMessage,
      })
      localStorage.setItem("user", JSON.stringify(responseMe.data));
      userContext.setUserData(responseMe.data);
      navigate("/teachers/dashboard", { state: { migrationError: true } });
    }
    userContext.setFormProgress(100);
  };

  const handleOption = (value: string) => {
    setOptionSelected({
      disable: false,
      option: value,
    });
  };

  useEffect(() => {
    if (adminError.adminError && userContext.userData.edu.user.migrationRequest === null) {
      let migrationFull = { status:true, errorMessage: adminError.adminErrorMessage };
      navigate("/teachers/dashboard", { state: { migrationFull } });
    }
  }, [adminError, userContext.userData])

  useEffect(() => {
    if (userContext.userData.edu.user.migrationRequest === null && !adminError.adminError) {
      let migration = { status: true, accepts: true, adminInfo: adminInfo };
      if (optionSelected.option === "no") {
        migration = {
          status: true,
          accepts: false,
          adminInfo: { adminName: "", schoolName: "" },
        };
      }
      navigate("/teachers/dashboard", { state: { migration } });
    }
  }, [userContext.userData]);

  return (
    <div className="teacher-migration mt-5">
      <h1 className="title">CONFIRM MIGRATION</h1>
      <p>
        Administrator {adminInfo.adminName} from {adminInfo.schoolName} has
        asked to add you as a teacher in their Skybrary School subscription. In
        order to protect your and your students' data you must choose one of the
        options below:
      </p>
      <>
        <button
          className={`migrate-option confirm ${
            optionSelected.option === "yes" ? "option-selected" : ""
          }`}
          type="button"
          onClick={() => handleOption("yes")}
        >
          Yes, Please Move Me!
        </button>
        <button
          className={`migrate-option cancel ${
            optionSelected.option === "no" ? "option-selected" : ""
          }`}
          type="button"
          onClick={() => handleOption("no")}
        >
          No, DO NOT Move Me!
        </button>
      </>
      <button
        type="button"
        className={`btn-di-serria confirm-button mt-4 btn-spinner-indicator ${
          optionSelected.disable ? "disabled" : ""
        }`}
        disabled={optionSelected.disable}
        onClick={handleSubmit}
      >
        CONFIRM
      </button>
      <Footer/>
    </div>
  );
};

export default MigrateConfirm;
