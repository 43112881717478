import { useEffect, useState } from "react";
import { getFooterItems } from "../../api/rifService";

const Footer = () => {
  const [footerContent, setFooterContent] = useState<any>([]);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    getFooterItems().then((response: any) => {
      setFooterContent(response);
    });
  }, []);

  return (
    <footer className="school-footer">
      <div className="container">
        <div className="footer-centered">
          <div className="footer-box social-widgets">
            <div className="column-content">
              <h2 className="footer-subtitle">Follow Us</h2>
              <div className="social">
                <a
                  href="https://www.facebook.com/skybraryforkids/"
                  className="icon facebook"
                  title="Follow us on Facebook"
                ></a>
                <a
                  href="https://instagram.com/skybraryforkids"
                  className="icon instagram"
                  title="Follow us on Instagram"
                ></a>
                <a
                  href="https://twitter.com/RIFWEB"
                  className="icon twitter"
                  title="Follow us on Twitter"
                ></a>
                <a
                  href="https://www.tiktok.com/@rif.readingisfundamental"
                  className="icon tiktok"
                  title="Follow us on TikTok"
                ></a>
              </div>
            </div>
          </div>

          <div className="footer-box sitemap">
            <div className="column-content">
              <h2 className="footer-subtitle">Site Map</h2>
              <div className="links">
                <ul>
                  <li className="first menu-item">
                    <a href={footerContent[1]?.link.url}>{footerContent[1]?.link.title}</a>
                  </li>
                  <li className="menu-item">
                    <a href={footerContent[0]?.link.url}>{footerContent[0]?.link.title}</a>
                  </li>
                  <li className="menu-item">
                    <a href={footerContent[3]?.link.url}>{footerContent[3]?.link.title}</a>
                  </li>
                  <li className="menu-item">
                    <a href={footerContent[2]?.link.url}>{footerContent[2]?.link.title}</a>
                  </li>
                  <li className="menu-item">
                    <a href={footerContent[5]?.link.url}>{footerContent[5]?.link.title}</a>
                  </li>
                  <li className="menu-item">
                    <a href={footerContent[4]?.link.url}>{footerContent[4]?.link.title}</a>
                  </li>
                  <li className="menu-item">
                    <a href={footerContent[7]?.link.url}>{footerContent[7]?.link.title}</a>
                  </li>
                  <li className="menu-item">
                    <a href={footerContent[6]?.link.url}>{footerContent[6]?.link.title}</a>
                  </li>
                  <li className="menu-item">
                    <a href={footerContent[9]?.link.url}>{footerContent[9]?.link.title}</a>
                  </li>
                  <li className="menu-item">
                    <a href={footerContent[8]?.link.url}>{footerContent[8]?.link.title}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom-footer">
        < p className="text-center">
          ©{currentYear} Reading Is Fundamental, Inc. Skybrary® is property of Reading Is
          Fundamental, Inc.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
