import { sendEvent } from "@api/googleAnalyticsService";
import { getClassroomHash } from "@api/rifService";
import CleverSignIn from "@components/Clever/CleverSignIn";
import TextError from "@components/TextError";
import { useCleverContext } from "@context/clever";
import { useUserContext } from "@context/user";
import { isCleverTheme } from "@utils/helpers";
import { ErrorMessage, Field, Form, Formik, FormikValues } from "formik";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

const WelcomeStudentsForm = () => {
  const validate = Yup.object({
    classCode: Yup.string().required("Classroom code is required."),
  });
  const navigate = useNavigate();
  const userContext = useUserContext();
  const cleverContext = useCleverContext();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const showLoginClever =
    process.env.REACT_APP_ENV_NAME === "local" ||
    query.get("clever-test") === "1";
  const pageUrl = location.pathname;
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [textError, setTextError] = useState("");

  const isClever = useMemo(
    () => isCleverTheme(userContext, cleverContext),
    [userContext, cleverContext],
  );

  const handleSubmit = async (values: FormikValues) => {
    userContext.setFormProgress(50);
    const response = await getClassroomHash(values.classCode);
    if (response.status) {
      sendEvent({
        event: "access_code",
        eventProps: {
          category: "form_submit",
          action: "Student enters access code",
          label: pageUrl,
          value: "0",
        },
      });
      setButtonDisabled(true);
      userContext.setFormProgress(100);
      navigate(`/sigin/classroom/${response.data.data.hash}`, {
        state: { access: true },
      });
    } else {
      setTextError(response.error.response.data.errorMessage);
      setButtonDisabled(false);
      userContext.setFormProgress(0);
      values.classCode = "";
    }
  };

  return (
    <div className={`${isClever && "student-log-in"}`}>
      <h1 className={`sign-in-title`}>
        {isClever ? `Welcome, students!` : `Welcome Students`}
      </h1>
      <div className="container p-4">
        <div className="row">
          <div className="">
            {/*<NavForm />*/}
            <div className="login-form px-2">
              <Formik
                initialValues={{
                  classCode: "",
                }}
                validationSchema={validate}
                onSubmit={handleSubmit}
              >
                {({ errors }) => (
                  <Form>
                    <ErrorMessage
                      name="classCode"
                      component={() => (
                        <div className="error-message">
                          <TextError wrapper="div" text={errors.classCode} />
                        </div>
                      )}
                    />
                    {textError !== "" && (
                      <div className="error-message">
                        <TextError text={textError} wrapper="div" />
                      </div>
                    )}
                    <div className="col-sm-10 col-lg-4 offset-sm-1 offset-lg-4 mb-3">
                      <label
                        className="label form-label"
                        htmlFor="password-signIn"
                      >
                        Enter your classroom code
                        <span className="form-required"> *</span>
                      </label>
                      <Field
                        className={`form-control class-code mb-5 ${
                          errors.classCode && "is-invalid"
                        }`}
                        type="text"
                        id="classCode"
                        name="classCode"
                      />
                    </div>
                    <div className="d-grid col-6 mx-auto">
                      <button
                        className={`button log-in-button ${isClever ? "btn-moderate-green" : "btn-di-serria"} btn-spinner-indicator`}
                        type="submit"
                        disabled={buttonDisabled}
                      >
                        Log in
                      </button>
                      {showLoginClever && isClever && (
                        <p className="sign-in-separator">
                          or sign in with district
                        </p>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
              {showLoginClever && <CleverSignIn isValidForm={true} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeStudentsForm;
