import Modal from "react-bootstrap/Modal";

function PromoModal(props: { show: boolean; onClick: any; class: string; body:any;}) {
  return (
    <Modal centered show={props.show} className={`${props.class}`} onHide={() => props.onClick()} backdropClassName="dark-backdrop">
      <Modal.Body>
        {props.body}
        <div className="btn-wrapper">
          <button className="btn btn-confirm mx-auto" onClick={props.onClick}>OK</button>
        </div>
        </Modal.Body>
    </Modal>
  );
}

export default PromoModal;