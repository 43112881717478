import React from "react";

const AboutPlansModal = (props: {onClick: any;}) => {
  return (
    <div className="modal-body-wrapper">
      <p className="mb-4">
        <strong>What's the Difference Between Our Plans?</strong>
      </p>
      <p className="mb-4">
        <b>Classroom Plan:</b>
        <br/>
        $179
        <br/>
        You get 1 class with 35 seats
      </p>
      <p className="mb-4">
        <b>School Plan:</b>
        <br/>
        $1450
        <br/>
        You get 10 classes, each with 35 seats
        <br/>
        *Savings of $340
      </p>
      <p className="mb-4">
        <b>Both Plans Feature:</b>
        <br/>
        A 12-month license
        <br/>
        24/7 School and Home Access
        <br/>
        Complete library of 900+ books & 200+ videos featuring host LeVar Burton
        <br/>
        Access to 40 educator created Lesson Plans aligned to standards, detailed reports monitoring reading & viewing activity for students and classroom, and much more!
      </p>
    </div>
  )
}

export default AboutPlansModal;