import { Formik, Form, FormikValues, Field, ErrorMessage } from "formik";
import TextError from "../../TextError";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import logo from "../../../images/logo-skybrary-school.png";
import { editPassword } from "../../../api/rifService";
import { sha256 } from "js-sha256";
import UserContext from "../../../context/user";

const EditPasswordForm = () => {
  const userContext = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    if (location.state !== null) {
      document.title = `${location.state.data.emailAddress} | Reading Is Fundamental`;
      setEmail(location.state.data.emailAddress);
      setToken(location.state.token);
    }
  }, [location.state]);

  const validate = Yup.object({
    password: Yup.string()
      .matches(
        /(?=.*[a-z])/,
        "Password must contain at least one lowercase character."
      )
      .matches(
        /(?=.*[A-Z])/,
        "Password must contain at least one uppercase character."
      )
      .min(8, "")
      .required("Please enter a password."),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref("password"), null])
      .min(1, "true"),
  });

  const [errorRecover, setErrorRecover] = useState(false);

  const handleRecoverySubmit = async (values: FormikValues) => {
    userContext.setFormProgress(50);
    const passwordEncrypted = sha256(
      `${email}${values.password}*0*eDimMlW$dfzqO#o7%hnU@Uv&vBARs`
    );
    setErrorRecover(false);
    const response = await editPassword(email, token, passwordEncrypted);
    if (response) {
      userContext.setFormProgress(100);
      navigate("/user", { state: { passwordRecoverSuccess: true } });
    } else {
      setErrorRecover(true);
    }
    userContext.setFormProgress(100);
  };

  return (
    <>
      <div className="container p-4">
        <div className="row p-4">
          <Formik
            initialValues={{
              password: "",
              passwordConfirm: "",
            }}
            validationSchema={validate}
            onSubmit={handleRecoverySubmit}
            validateOnBlur={false}
            validateOnChange={true}
          >
            {({ errors, values }) => (
              <Form>
                <div className="logo-container">
                  <img className="img" src={logo} width="130" alt="logo" />
                </div>

                <div className="edit-password-form">
                  {!errorRecover && (
                    <div className="text-send ps-5">
                      You have just used your one-time login link. It is no
                      longer necessary to use this link to log in. Please change
                      your password.
                    </div>
                  )}
                  {errorRecover && (
                    <TextError
                      wrapper="div"
                      text="There was an error, We were not able to change your password, please try again"
                    />
                  )}
                  {submit && (
                    <ErrorMessage
                      name="password"
                      component={() => (
                        <div className="error-message">
                          <TextError text={errors.password} wrapper={"div"} />
                        </div>
                      )}
                    />
                  )}
                  <div className="mb-3">
                    <label
                      className="label edit-password-label mt-4 mb-3"
                      htmlFor="email-signIn"
                    >
                      E-mail address
                    </label>
                    <p className="email">{email}</p>
                    <label className="label form-label">Password</label>
                    <Field
                      className="form-control"
                      type="password"
                      name="password"
                    />
                    <div className="confirm-password">
                      <label className="label form-label">
                        Confirm Password
                      </label>
                      <Field
                        className="form-control"
                        type="password"
                        name="passwordConfirm"
                      />
                      {errors.passwordConfirm && (
                        <label className="password-match">
                          Password match: <span className="no-matches">no</span>
                        </label>
                      )}
                      {!errors.passwordConfirm &&
                        values.password.length > 7 &&
                        values.passwordConfirm.length > 7 && (
                          <label className="password-match">
                            Password match: <span className="matches">yes</span>
                          </label>
                        )}
                    </div>
                    <div className="description mt-2">
                      To change the current password, enter the new password in
                      both fields.
                    </div>
                  </div>
                  <button
                    className="button recovery-button btn-di-serria btn-spinner-indicator mt-2"
                    type="submit"
                    onClick={() => setSubmit(true)}
                  >
                    SAVE
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default EditPasswordForm;
