import Header from "../../../header/Header";
import {ErrorMessage, Field, Form, Formik, FormikValues} from "formik";
import TextError from "../../../TextError";
import * as Yup from "yup";
import {Link, useNavigate} from "react-router-dom";
import {formSteps} from "../../../../context/Forms/purchaseContext";
import React, {useContext, useEffect, useState} from "react";
import UserContext from "../../../../context/user";
import {sha256} from "js-sha256";
import {getSchoolSubscriptions, userGetMe, userSignIn} from "../../../../api/rifService";
import {BACK_URL} from "../../../../utils/constants";
import {encodePassword, LogoutHelper} from "../../../../utils/helpers";
import TextWarning from "../../../TextWarning";

const RedeemAccountInfoForm = (props: { page: string }) => {
  const navigate = useNavigate();

  const schemaValidate = Yup.object({
    email: Yup.string()
      .required("Email Address field is required.")
      .email(
        "The email address entered is not valid. Please double check that there are no special characters present."
      ),
    password: Yup.string()
      .required("Password field is required.")
      .matches(
        /(?=.*[a-z])/,
        "Password must contain at least one lowercase character."
      )
      .matches(
        /(?=.*[A-Z])/,
        "Password must contain at least one uppercase character."
      )
      .min(8, "Password must be at least 8 characters in length."),
  });

  const userContext = useContext(UserContext);
  const [loginError, setLoginError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [textError, setTextError] = useState("");
  const [emailOfLoggedUser, setEmailOfLoggedUser] = useState("");
  let decideNewAccount = true

  const showActiveUser = async () => {
    if (userContext.token && emailOfLoggedUser == '') {
      let userResponse = await userGetMe(userContext.token);
      setEmailOfLoggedUser(userResponse.data.emailAddress)
    }
  }
  showActiveUser()

  const handleSubmit = (values: FormikValues) => {
    userContext.setFormProgress(50);
    setButtonDisabled(true);
    const emailLowercase = values.email.toLocaleLowerCase();
    const passwordEncrypted = sha256(
      `${emailLowercase}${values.password}*0*eDimMlW$dfzqO#o7%hnU@Uv&vBARs`
    );
    const passwordEncoded = encodePassword(values.password);

    userSignIn(emailLowercase, passwordEncrypted, passwordEncoded).then(
      (data) => {
        setLoginError(false);
        if (data.status) {
          userContext.login(data.data);
          userContext.setFormProgress(100);
          const token = data.data.auth.accessToken;
          if (!data.data.userResponse.data.type.includes('edu', 0)) {
            setButtonDisabled(false);
            userContext.setFormProgress(100)
            setTextError(
              <div>
                Looking for Skybrary for Families?{" "}
                <a
                  href="https://www.skybrary.org/user/sign-in"
                  className="login-link">
                  Log in here
                </a>
              </div>
            );
          } else {
            userContext.changeLoginFlag();
            const schoolId = data.data.userResponse.data.edu.user.rrkSchoolId;
            const requestConfig = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };
            getSchoolSubscriptions(requestConfig, schoolId, null).then(
              (schoolSubscription) => {
                console.log('get sub 12')
                userContext.saveSubscriptions(schoolSubscription, data.data.userResponse.data.edu.user.rrkSchoolId);
                navigate("/gift/redeem/subscription-info")
              }
            )
          }
        } else {
          setButtonDisabled(false);
          setLoginError(true);
          userContext.setFormProgress(100);
        }
      }).catch((error) => {
      setTextError("There was an error, please try again later.");
      setButtonDisabled(false);
      userContext.setFormProgress(0);
    });
  };

  let giftToken = localStorage.getItem('giftToken');

  useEffect(() => {
    document.title = "Your Info | Reading Is Fundamental";
    if (giftToken === null){
      navigate('/gift/redeem/manual')
    }
  });


  const useAnotherAccount = () => {
    LogoutHelper()
    navigate("/gift/redeem/account-info")
  }

  const useLoggedAccount = () => {
    navigate("/gift/redeem/subscription-info")
  }

  const decideCurrentOrAnotherAccount = () => {
    return (<div className="row button-redeem mt-2">
      <div className="col-6 mx-auto mb-0 px-0 text-end">
        <button className="button btn-red-damask" type="submit" onClick={useLoggedAccount}>
          Yes
        </button>
      </div>
      <div className="col-6 mx-auto mb-0 px-0">
        <button className="button btn-red-damask" type="submit" onClick={useAnotherAccount}>
          No
        </button>
      </div>
    </div>)
  }

  return (
    <>
      <Header
        steps={formSteps[props.page]}
        activeStep={2}
        previousPath={BACK_URL}
        buttonText="<< Back"
      />
      {userContext.token && emailOfLoggedUser !== "" && (
        <>
          <div className="col-6 mx-auto mb-0 px-0 text-end">
            <TextWarning>
              <p className="text-center m-1 p-1">Do you want to redeem the gift with the logged
                user <b>{emailOfLoggedUser}</b> ?</p>
            </TextWarning>
          </div>
          {decideCurrentOrAnotherAccount()}
        </>
      )}
      {userContext.token && emailOfLoggedUser === "" && (
        <>
          <div className="col-6 mx-auto mb-0 px-0 text-end">
            <TextWarning>
              <p className="text-center m-1 p-1">Do you want to redeem the gift with the logged
                user <span className="placeholder col-3"></span></p>
            </TextWarning>
          </div>
          {decideCurrentOrAnotherAccount()}
        </>
      )}
      {textError && (
        <TextError
          text={textError}
          wrapper="div"
        />
      )}
      {!userContext.token && (
        <div className="register-form mx-auto">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="login-form">
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                    validationSchema={schemaValidate}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                    validateOnBlur={false}
                  >
                    {({errors}) => (
                      <Form>
                        <h1 className="step-title text-center">
                          Step 2 of 3 : Your Account
                        </h1>
                        {loginError && (
                          <TextError
                            wrapper="div"
                            text="Not a valid Email / Password combination"
                          />
                        )}
                        {Object.keys(errors).length > 0 && (
                          <div className="errors">
                            <div className="col-1 p-0 error-logo"/>
                            <ul className="p-0 errors-list">
                              <ErrorMessage
                                name="email"
                                component={() => (
                                  <TextError wrapper="li" text={errors.email}/>
                                )}
                              />
                              <ErrorMessage
                                name="password"
                                component={() => (
                                  <TextError
                                    wrapper="li"
                                    text={errors.password}
                                  />
                                )}
                              />
                            </ul>
                          </div>
                        )}
                        <div className="step-info text-center">
                          Enter your Email and Password
                        </div>
                        <div className="mx-0 my-3 row w-100">
                          <label
                            className="form-label col-form-label text-end p-0"
                            htmlFor="password"
                          >
                            Email Address
                            <span className="form-required"> *</span>
                          </label>
                          <Field
                            className={`form-control ${
                              errors.email && "is-invalid"
                            }`}
                            type="email"
                            name="email"
                          />
                        </div>
                        <div className="mx-0 my-3 row w-100">
                          <label
                            className="form-label col-form-label text-end p-0"
                            htmlFor="password"
                          >
                            Password
                            <span className="form-required"> *</span>
                          </label>
                          <Field
                            className={`form-control ${
                              errors.password && "is-invalid"
                            }`}
                            type="password"
                            name="password"
                          />
                        </div>
                        <div className="row button-redeem">
                          <div className="col-12 mx-auto mb-0 px-0 text-center">
                            <button
                              className="button btn-red-damask button-login btn-spinner-indicator"
                              type="submit"
                              disabled={buttonDisabled}
                            >
                              NEXT
                            </button>
                          </div>
                        </div>
                        <div className="mx-auto my-4 row w-100 privacy-policy text-center">
                          <div className="w-100 px-0">
                            <Link
                              to="/gift/redeem/your-info"
                              className="tos-link"
                            >
                              Create a new Account
                            </Link>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>

      )}
    </>
  )
};
export default RedeemAccountInfoForm;
