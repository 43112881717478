import { useState, useContext, useEffect, useMemo } from "react";
import { Formik, Field, Form } from "formik";
import Header from "../../header/Header";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import UserContext from "../../../context/user";
import CleverContext from "@context/clever";
import {
  BACK_URL,
  registerText,
  SUBSCRIPTIONS_PER_SCHOOL_PLAN,
} from "../../../utils/constants";
import GiftContext from "../../../context/Forms/giftContext";
import TextError from "../../TextError";
import { useNavigate, useParams } from "react-router-dom";
import {
  addCleverSubscriptions,
  addSubscriptions,
  createGift,
  createUserSubscription,
  getSchoolReports,
  getSchoolSubscriptions,
} from "../../../api/rifService";
import Renewals from "../../Dashboard/Admin/AdminSections/Renewals";
import { decodeValue, isCleverTheme } from "../../../utils/helpers";
import { sendEvent } from "../../../api/googleAnalyticsService";
import { FormPurchaseContext } from "@context/Forms/purchaseContext";
import {
  ClassicRenewalData,
  CleverRenewalData,
  CreditCardFormData,
} from "@customTypes/dataTypes";

const FormCreditCard = (props: {
  page: string;
  formSteps: any;
  clientSecret: string;
  errorSubmitting: boolean;
  onSubmitError: any;
}) => {
  const orderContext: any = useContext(FormPurchaseContext);
  const giftContext: any = useContext(GiftContext);
  let userContext: any = useContext(UserContext);
  let cleverContext: any = useContext(CleverContext);
  const elements = useElements();
  const stripe = useStripe();
  const [message, setMessage] = useState<null | string | undefined>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  let previousPath = BACK_URL;
  let nextPath = "";
  let buttonText = "<< Back";
  const navigate = useNavigate();
  const params = useParams();
  const [token, setToken] = useState<string | undefined>("");
  const [submitSuccess, setSubmitSuccess] = useState(true);
  const [submitSuccessUpgrade, setSubmitSuccessUpgrade] = useState(true);
  const [showError, setShowError] = useState(false);
  let activeStep = 3;

  const isCleverSession: boolean = useMemo(
    () => isCleverTheme(userContext, cleverContext),
    [userContext, cleverContext],
  );

  let usesGiftContext: boolean =
    props.page === "gift" || props.page === "try_it_free_purchase";

  const usesOrderContext: boolean =
    props.page === "purchase" || props.page === "try_it_free_purchase_email" || props.page === "admin_purchase";

  let creditCardFormData: CreditCardFormData;

  if (usesOrderContext) {
    creditCardFormData = {
      classroomPlanTotal:
        orderContext.thirdStep.classroomPlan *
        orderContext.thirdStep.classroomPlanPrice,
      schoolPlanTotal:
        orderContext.thirdStep.schoolPlan *
        orderContext.thirdStep.schoolPlanPrice,
      classroomPlan: orderContext.thirdStep.classroomPlan,
      schoolPlan: orderContext.thirdStep.schoolPlan,
      orderTotal: orderContext.thirdStep.orderTotal,
      discount: orderContext.thirdStep.discountCode,
    };
  } else if (usesGiftContext) {
    creditCardFormData = {
      classroomPlanTotal:
        giftContext.secondStep.classroomPlan *
        giftContext.secondStep.classroomPlanPrice,
      schoolPlanTotal:
        giftContext.secondStep.schoolPlan *
        giftContext.secondStep.schoolPlanPrice,
      classroomPlan: giftContext.secondStep.classroomPlan,
      schoolPlan: giftContext.secondStep.schoolPlan,
      orderTotal: giftContext.secondStep.orderTotal,
      discount: giftContext.secondStep.discountCode,
    };
  }

  if (
    props.page === "purchase" ||
    props.page === "try_it_free_purchase_email"
  ) {
    nextPath = "/purchase";
  } else if (props.page === "gift") {
    nextPath = "/gift";
  } else if (props.page === "try_it_free_purchase") {
    activeStep = 2;
  } else if (props.page === "admin_purchase") {
    nextPath = "/adm/checkout/success";
  }

  if (giftContext.prevUrl) {
    previousPath = giftContext.prevUrl;
    buttonText = "<< Back to Dashboard";
  }

  const upgradeTestAccount = async (paymentIntentId: any) => {
    userContext.setFormProgress(50);
    userContext.saveIsLogging(true);
    let data: any;
    if (giftContext.secondStep.discountCode > 0) {
      data = {
        payment: {
          lines: {
            superPackAmount: giftContext.secondStep.schoolPlan,
            singlePackAmount: giftContext.secondStep.classroomPlan,
            discountCode: giftContext.secondStep.promoCode,
          },
          paymentIntentId: paymentIntentId,
        },
        loggedInUser: {
          rrkEduUserId: userContext.userData.edu.user.rrkEduUserId,
          rrkSchoolId: userContext.userData.edu.user.rrkSchoolId,
        },
        type: "from-trial",
      };
    } else {
      data = {
        payment: {
          lines: {
            superPackAmount: giftContext.secondStep.schoolPlan,
            singlePackAmount: giftContext.secondStep.classroomPlan,
          },
          paymentIntentId: paymentIntentId,
        },
        loggedInUser: {
          rrkEduUserId: userContext.userData.edu.user.rrkEduUserId,
          rrkSchoolId: userContext.userData.edu.user.rrkSchoolId,
        },
        type: "from-trial",
      };
    }

    const response = await createUserSubscription(data);
    if (response.status) {
      orderContext.setThirdStep({
        ...orderContext.thirdStep,
        isOrderForm: false,
        orderId: response.data.data.orderId,
      });
      userContext.saveFromTrial(true);
      setToken(`Bearer ${response.data.data.token.access_token}`);
    } else {
      userContext.setFormProgress(100);
      setSubmitSuccessUpgrade(false);
      setIsButtonDisabled(false);
      props.onSubmitError();
      setMessage(
        "There was an error, We were not able to upgrade your account, please try again",
      );
    }
  };

  const sendToGoogleAnalytics = () => {
    let plans = [];
    let contextPaymentData =
      props.page === "gift" ? giftContext.secondStep : orderContext.thirdStep;
    if (Number(contextPaymentData.classroomPlan) > 0) {
      plans.push({
        item_id: "Classroom Plan",
        item_name: "Classroom Plan",
        coupon: contextPaymentData.promoCode,
        discount: contextPaymentData.discountCode,
        price: contextPaymentData.classroomPlanPrice,
        currency: "USD",
        quantity: contextPaymentData.classroomPlan,
      });
    }

    if (Number(contextPaymentData.schoolPlan) > 0) {
      plans.push({
        item_id: "School Plan",
        item_name: "School Plan",
        coupon: "",
        discount: "",
        price: contextPaymentData.schoolPlanPrice,
        currency: "USD",
        quantity: contextPaymentData.schoolPlan,
      });
    }

    sendEvent({
      event: "add_payment_info",
      eventProps: {
        coupon: contextPaymentData.promoCode,
        currency: "USD",
        items: plans,
        payment_type: "Credit Card",
        value: contextPaymentData.orderTotal,
      },
    });
  };
  useEffect(() => {
    if (token) {
      userContext.setFormProgress(100);
      navigate("/purchase/success", {
        state: {fromTrial: true, access_token: token, showSuccess: true},
      });
    }
  }, [userContext.fromTrial, token]);

  const registerUser = async (pymentIntenId: any) => {
    userContext.setFormProgress(50);
    let data;
    if (props.page === "purchase") {
      data = {
        school: {
          name: orderContext.secondStep.schoolName,
          street: orderContext.secondStep.street,
          countryId: orderContext.secondStep.countryId,
          stateId: orderContext.secondStep.stateId,
          city: orderContext.secondStep.city,
          zipcode: orderContext.secondStep.zipcode,
          phone: orderContext.secondStep.phone,
          typeId: orderContext.secondStep.typeId,
          isTitleOne: orderContext.secondStep.isTitleOne,
        },
        user: {
          firstName: orderContext.secondStep.firstName,
          lastName: orderContext.secondStep.lastName,
          emailAddress: orderContext.registerData.email,
          password: decodeValue(orderContext.registerData.password),
          position: orderContext.secondStep.position,
          optIn: true,
        },
        payment: {
          lines: {
            superPackAmount: orderContext.thirdStep.schoolPlan,
            singlePackAmount: orderContext.thirdStep.classroomPlan,
            discountCode: orderContext.thirdStep.promoCode,
          },
          paymentIntentId: pymentIntenId,
        },
        type: "direct",
      };
    } else {
      data = {
        user: {
          firstName: orderContext.registerData.firstName,
          lastName: orderContext.registerData.lastName,
          emailAddress: orderContext.registerData.email,
          password: decodeValue(orderContext.registerData.password),
          position: "1",
          optIn: true,
        },
        payment: {
          lines: {
            superPackAmount: orderContext.thirdStep.schoolPlan,
            singlePackAmount: orderContext.thirdStep.classroomPlan,
            discountCode: orderContext.thirdStep.promoCode,
          },
          paymentIntentId: pymentIntenId,
        },
        emailInfo: params.user,
        type: "from-email",
      };
    }

    const response = await createUserSubscription(data);
    if (response.status) {
      userContext.setFormProgress(100);
      orderContext.setThirdStep({
        ...orderContext.thirdStep,
        orderId: response.data.data.subscriptions[0].orderId,
      });
      sendToGoogleAnalytics();
      navigate("/purchase/success", { state: { showSuccess: true } });
    } else {
      userContext.setFormProgress(100);
      setSubmitSuccess(false);
      setIsButtonDisabled(false);
      setMessage(
        "There was an error, We were not able to create your account, please try again",
      );
      props.onSubmitError();
    }
  };

  const handleSubmit = async () => {
    userContext.setFormProgress(50);
    setIsButtonDisabled(true);
    setSubmitSuccess(true);
    setSubmitSuccessUpgrade(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardElem = elements.getElement(CardElement);
    if (cardElem) {
      let email;
      if (
        props.page === "purchase" ||
        props.page === "try_it_free_purchase_email"
      ) {
        email = orderContext.registerData.email;
      } else if (props.page === "gift") {
        email = giftContext.firstStep.buyerEmail;
      }

      const payload = await stripe.confirmCardPayment(props.clientSecret, {
        setup_future_usage: "on_session",
        payment_method: {
          card: cardElem,
        },
      });
      console.log("confirm card payment", payload);

      if (payload.error) {
        if (payload.error.code === "invalid_number") {
          setMessage("Your credit card number is invalid. Please try again.");
        } else {
          setMessage(payload.error.message);
        }
        setSubmitSuccess(false);
        setIsButtonDisabled(false);
        userContext.setFormProgress(100);
      } else {
        console.log("prop page", props.page);
        if (
          props.page === "purchase" ||
          props.page === "try_it_free_purchase_email"
        ) {
          registerUser(payload.paymentIntent.id);
        } else if (props.page === "gift") {
          const giftData = {
            catalogId: 2,
            emailAddress: giftContext.firstStep.buyerEmail,
            emailTo: giftContext.firstStep.recipientEmail,
            emailFrom: giftContext.firstStep.buyerEmail,
            firstNameTo: giftContext.firstStep.recipientFirstName,
            firstNameFrom: giftContext.firstStep.buyerFirstName,
            lastNameTo: giftContext.firstStep.recipientLastName,
            lastNameFrom: giftContext.firstStep.buyerLastName,
            stripePlanId: "1_year",
            stripeCoupon: giftContext.secondStep.promoCode,
            superPackQuantity: giftContext.secondStep.schoolPlan,
            singlePackQuantity: giftContext.secondStep.classroomPlan,
            paymentIntentId: payload.paymentIntent.id,
            paymentMethodId: payload.paymentIntent.payment_method,
            discountCode: giftContext.secondStep.promoCode,
            isCleverRedeemable: cleverContext.isCleverUser() ? 1 : 0,
          };

          const response = await createGift(giftData);
          userContext.setFormProgress(100);
          if (response.status) {
            orderContext.setThirdStep({
              ...orderContext.thirdStep,
              orderId: response.data.data.stripePlanId,
            });
            sendToGoogleAnalytics();
            navigate("/gift/success", { state: { showSuccess: true } });
          } else {
            setMessage(
              "There was an error, We were not able to create your gift, please try again",
            );
          }
        } else if (props.page === "try_it_free_purchase") {
          upgradeTestAccount(payload.paymentIntent.id);
        } else if (props.page === "admin_purchase") {
          console.log("admin purchase page");
          setShowError(false);
          userContext.setFormProgress(50);

          let purchaseRenewData: CleverRenewalData | ClassicRenewalData;
          let summaryData = cleverContext.getSummaryData();
          const schoolId = userContext.userData.edu.user.rrkSchoolId;
          const discountFound = orderContext.thirdStep.discountCode === 0;
          const requestConfig = {
            headers: {
              Authorization: `Bearer ${userContext.token}`,
            },
          };

          if (isCleverSession) {

            const renewSubscriptions = cleverContext.getRenewSubscriptions();

            purchaseRenewData = {
              payment: {
                paymentMethod: payload.paymentIntent.payment_method,
                paymentIntentId: payload.paymentIntent.id,
                lines: {
                  superPackAmount: orderContext.thirdStep.schoolPlan,
                  singlePackAmount: orderContext.thirdStep.classroomPlan,
                  discountCode: discountFound
                    ? orderContext.thirdStep.promoCode
                    : null,
                },
              },
              user: {
                internalCleverUserId: summaryData.internalCleverUserId,
                eduUserId: summaryData.eduUserId,
              },
              type: 'direct',
              subscriptionsRenewals: (renewSubscriptions && renewSubscriptions.length > 0)
                ? renewSubscriptions.map(sub => parseInt(sub.subId, 10))
                : [],
            } as CleverRenewalData;

            const response = await addCleverSubscriptions(
              requestConfig,
              purchaseRenewData,
            );
            if (response.status) {
              userContext.setFormProgress(100);
              localStorage.removeItem('renewSubscriptions')
              navigate(nextPath, {
                state: {
                  order: false,
                  orderId: response.data.order.rrkOrderId,
                },
              });
            } else {
              console.log("with error clever", response);
              setIsButtonDisabled(false);
              setShowError(true);
              setMessage(
                "There was an error, We were not able to add yours clever subscriptions, please try again",
              );
              userContext.setFormProgress(100);
              props.onSubmitError();
            }
          } else {
            purchaseRenewData = {
              superPackAmount: orderContext.thirdStep.schoolPlan,
              singlePackAmount: orderContext.thirdStep.classroomPlan,
              subscriptionsRenewals:
                orderContext.thirdStep.subscriptionsRenewals,
              discountCode: discountFound
                ? orderContext.thirdStep.promoCode
                : null,
              paymentMethodId: payload.paymentIntent.payment_method,
              paymentIntentId: payload.paymentIntent.id,
              method: "card",
            } as ClassicRenewalData;

            const response = await addSubscriptions(requestConfig, purchaseRenewData);
            if (response.status) {
              userContext.setFormProgress(75);
              await getSchoolSubscriptions(
                requestConfig,
                userContext.userData.edu.user.rrkSchoolId,
                null,
              ).then((schoolSubscription) => {
                userContext.saveSubscriptions(
                  schoolSubscription,
                  userContext.userData.edu.user,
                );
                userContext.setFormProgress(100);

                getSchoolReports(requestConfig, schoolId).then(
                  (schoolReport) => {
                    userContext.saveSchoolReports(schoolReport);
                  },
                );

                navigate(nextPath, {
                  state: {order: false, orderId: response.data.order.orderId},
                });
              });
            } else {
              console.log("with error clasic", response);
              setIsButtonDisabled(false);
              setShowError(true);
              setMessage(
                "There was an error, We were not able to add the subscription, please try again",
              );
              userContext.setFormProgress(100);
              props.onSubmitError();
            }
          }
        }
      }
    }

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error?.type === "card_error" || error?.type === "validation_error") {
      setMessage(error.message);
      setIsButtonDisabled(false);
    } else {
      setMessage("An unexpected error occurred.");
      setSubmitSuccess(false);
      setIsButtonDisabled(false);
    }
    setIsButtonDisabled(false);
    userContext.setFormProgress(100);
  };

  const cardOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: isCleverSession ? "#184378" : "#237f45",
        color: isCleverSession ? "#184378" : "#237f45",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        fontFamily: "Arial, Lato, Verdana, sans-serif",
        fontWeight: "700",
        "::placeholder": {
          color: isCleverSession ? "#5B6774" : "#808080",
          fontWeight: "700",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#bf4039",
        iconColor: "#bf4039",
      },
    },
  };

  return (
    <>
      {props.page !== "admin_purchase" && (
        <Header
          steps={props.formSteps}
          activeStep={activeStep}
          previousPath={previousPath}
          buttonText={buttonText}
        />
      )}
      <div className="register-form mx-auto last-steps-form">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className={`login-form ${isCleverSession && "mt-3"}`}>
                {!submitSuccess && (
                  <div className="my-4">
                    <TextError wrapper="div" text={message} />
                  </div>
                )}
                {props.errorSubmitting && showError && (
                  <div className="my-4">
                    <TextError wrapper="div" text={message} />
                  </div>
                )}
                {!submitSuccessUpgrade && (
                  <TextError
                    wrapper="div"
                    text="Something went wrong trying to create EDU subscription."
                  />
                )}
                <Formik
                  initialValues={{}}
                  onSubmit={handleSubmit}
                  validateOnBlur={false}
                  validateOnChange={false}
                >
                  {({ isSubmitting }) => {
                    const classroomPlansAmount: number =
                      Number(creditCardFormData.classroomPlan) +
                      Number(creditCardFormData.schoolPlan) *
                        SUBSCRIPTIONS_PER_SCHOOL_PLAN +
                      (orderContext.renewals
                        ? orderContext.renewals.length
                        : 0);
                    return (
                      <Form style={{ textAlign: "center" }}>
                        <h1 className="step-title text-center">
                          {
                            registerText[props.page as keyof Object]
                              .purchaseStepsText[2]
                          }
                        </h1>
                        {!isCleverSession && (
                          <div className="step-info text-center">
                            {props.page === "gift" && (
                              <span>
                                Your gift is almost on its way! <br />
                              </span>
                            )}
                            {props.page === "admin_purchase" ? (
                              <>
                                <div>
                                  Purchase multiple plans or add subscriptions
                                  at anytime! All plans include UNLIMITED access
                                  to the service and connection to the Teacher
                                  Dashboard with 40 lesson plans.
                                </div>
                                <br />
                                <div>
                                  CLASSROOM PLAN: Provides up to 35 seats or
                                  individual logins per class.
                                </div>
                                <br />
                                <div>
                                  SCHOOL PLAN: A bundle of $
                                  {SUBSCRIPTIONS_PER_SCHOOL_PLAN} Classroom
                                  Plans for a discounted price. Each provides up
                                  to 35 seats per class, for a total of 350
                                  individual logins.
                                </div>
                                <br />
                                <div>
                                  Need more? For larger volume or district
                                  purchases, contact skybrary@RIF.org
                                </div>
                              </>
                            ) : (
                              <>
                                <span>
                                  Review your order details and enter credit
                                  card info in the boxes below.
                                </span>
                                <br />
                                <br />
                              </>
                            )}
                          </div>
                        )}
                        <h1 className="order">Order detail</h1>
                        {orderContext.renewals?.length > 0 && (
                          <>
                            <Renewals remove={false} />
                            <h1 className="extra-plans mx-4 mb-4">
                              Extra Plans
                            </h1>
                          </>
                        )}
                        <div className="mx-0 my-3 row w-100">
                          <label
                            className="form-label col-form-label p-0"
                            htmlFor="classroomPlan"
                          >
                            Classroom Plan{" "}
                            <em>($179 {isCleverSession && "per classroom"})</em>
                          </label>
                          <Field
                            as="select"
                            className="form-control form-select"
                            name="classroomPlan"
                            disabled
                          >
                            <option>{creditCardFormData.classroomPlan}</option>
                          </Field>
                        </div>
                        <div className="mx-0 my-3 row w-100">
                          <label
                            className="form-label col-form-label p-0"
                            htmlFor="schoolPlan"
                          >
                            School Plan
                            <em>
                              ($1450{" "}
                              {isCleverSession &&
                                `per ${SUBSCRIPTIONS_PER_SCHOOL_PLAN} classrooms`}
                              )
                            </em>
                          </label>
                          <Field
                            as="select"
                            className="form-control form-select"
                            name="schoolPlan"
                            disabled
                          >
                            <option>{creditCardFormData.schoolPlan}</option>
                          </Field>
                        </div>

                        <div className="centered-subscription-counter my-3">
                          <span>
                            You have {classroomPlansAmount} classroom{" "}
                            {classroomPlansAmount === 1 ? "plan" : "plans"} in
                            your shopping cart.
                          </span>
                        </div>

                        <div className="purchase-total mx-auto">
                          <div className="single_pack">
                            Classroom Plan(s)
                            <span className="total">
                              $
                              {creditCardFormData.classroomPlanTotal.toFixed(2)}
                            </span>
                          </div>
                          <div className="super_pack">
                            School Plan(s)
                            <span className="total">
                              ${creditCardFormData.schoolPlanTotal.toFixed(2)}
                            </span>
                          </div>
                          <div className="discount">
                            Discount
                            <span className="total">
                              $
                              {orderContext.thirdStep.classroomPlan === 0
                                ? "0.00"
                                : creditCardFormData.discount.toFixed(2)}
                            </span>
                          </div>
                          <div className="order-total">
                            Order Total
                            <span className="total">
                              ${creditCardFormData.orderTotal.toFixed(2)}
                            </span>
                          </div>
                        </div>
                        <h1
                          className={`order credit-card-info mt-3 ${isCleverSession && "mb-2"}`}
                        >
                          Credit Card Info
                        </h1>
                        <div className="credit-card-input">
                          <CardElement options={cardOptions} />
                        </div>
                        <div className="d-flex justify-content-center col-12 mx-auto mb-0">
                          <button
                            className={`btn ${isCleverSession ? "mt-4 btn-sky btn-moderate-green" : "btn-submit btn-brandy-punch"} btn-spinner-indicator`}
                            type="submit"
                            disabled={isButtonDisabled || isSubmitting}
                          >
                            SUBMIT PAYMENT
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormCreditCard;
