import { useNavigate, useParams } from "react-router-dom";
import OneTimeLogin from "./OneTimeLogin";
import { useState, useEffect } from "react";
import { RecoverPasswordStepTwo } from "../../../api/rifService";

const RecoveryPasswordStepTwo = () => {
  const navigate = useNavigate();
  const params = useParams();  
  
  const queryString = params.token;
  
  const [content, setContent] = useState(<div></div>);
  
  useEffect(() => {  
    document.title = "Reset Password | Reading Is Fundamental";
    const handleLoad = async () => {
      RecoverPasswordStepTwo(queryString).then((data) => {
        if (data.status) {
          setContent (<OneTimeLogin tokenVerification={data} queryString={queryString} />);
        } else {
          navigate("/user/password", {state: {errorRecovery: true}});
        }
      });
    };
    
    handleLoad();
  
  }, [queryString]);

  return (
    content
  );
};

export default RecoveryPasswordStepTwo;