import { validateGiftToken } from "@api/rifService";
import CleverSignIn from "@components/Clever/CleverSignIn";
import Header from "@components/header/Header";
import TextError from "@components/TextError";
import { useCleverContext } from "@context/clever";
import { formSteps } from "@context/Forms/purchaseContext";
import { useUserContext } from "@context/user";
import { BACK_URL, registerText, SKYBRARY_MAILTO } from "@utils/constants";
import { redeemEduGiftCodeEvent } from "@utils/events/googleAnalitycs";
import { ErrorMessage, Field, Form, Formik, FormikValues } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

const RedeemForm = (props: { page: string }) => {
  const schemaValidate = Yup.object({
    giftCode: Yup.string()
      .required("Subscription Code field is required.")
      .max(128),
  });
  const params = useParams();
  let giftCode: string = "";

  //This loaded only if the gift code is already set in the URL
  if (params.giftToken !== undefined) {
    giftCode = params.giftToken;
  }

  //Variables that handle error messages
  const [tokenUsedError, setTokenUsedError] = useState(false);
  const [cleverTokenError, setCleverTokenError] = useState(false);
  const [cleverSignInError, setCleverSignInError] = useState(false);

  //Variables that manage the buttons on the redeem dashboard
  const [eduButton, setEduButton] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);

  const navigate = useNavigate();
  const [path, setPath] = useState("");
  const userContext = useUserContext();
  const cleverContext = useCleverContext();
  const location = useLocation();
  const pageUrl = location.pathname;
  const query = new URLSearchParams(location.search);
  const showLoginClever =
    process.env.REACT_APP_ENV_NAME === "local" ||
    query.get("clever-test") === "1";

  const handleSubmit = async (values: FormikValues) => {
    userContext.setFormProgress(50);
    values.giftCode = values.giftCode.trim();
    const response = await validateGiftToken(values.giftCode);
    userContext.setFormProgress(100);

    setEduButton(false);
    setIsValidForm(false);

    if (!response.status) {
      setTokenUsedError(true);
      setCleverTokenError(false);
    }

    if (!response.gift["isClever"] && eduButton) {
      redeemEduGiftCodeEvent(pageUrl);
      localStorage.setItem(
        "giftMaxSubscriptions",
        response.data[0].quantity * 10 + response.data[1].quantity,
      );
      cleverContext.saveCleverGiftToken(values.giftCode);
      navigate(path);
    } else if (eduButton) {
      setCleverTokenError(true);
    } else if (!response.gift["isClever"]) {
      setCleverSignInError(true);
    } else {
      localStorage.setItem(
        "giftMaxSubscriptions",
        response.data[0].quantity * 10 + response.data[1].quantity,
      );
      cleverContext.saveCleverGiftToken(values.giftCode);
      setIsValidForm(true);
    }
  };

  const handleClick = (path: string) => {
    setEduButton(true);
    setPath(path);
  };

  const invalidRedeemCodeError = (
    <div>
      You have used an invalid, expired or previously redeemed gift code. Please
      contact us on {SKYBRARY_MAILTO} or 1 (877) RIF-READ for support.
    </div>
  );

  useEffect(() => {
    document.title = "Subscription Code | Reading Is Fundamental";
  }, []);

  return (
    <>
      <div className="purchase-page gift">
        <div className="container">
          <div className="row mx-auto main-content">
            <Header
              steps={formSteps[props.page]}
              activeStep={1}
              previousPath={BACK_URL}
              buttonText="<< Back"
            />
            <div className="register-form mx-auto">
              <div className="row">
                <div className="col">
                  <div className="login-form">
                    <Formik
                      initialValues={{
                        giftCode: giftCode,
                      }}
                      validationSchema={schemaValidate}
                      onSubmit={handleSubmit}
                      validateOnChange={false}
                      validateOnBlur={false}
                    >
                      {({ errors }) => (
                        <Form>
                          {tokenUsedError && (
                            <TextError
                              wrapper="div"
                              text={invalidRedeemCodeError}
                            />
                          )}
                          {cleverTokenError && (
                            <TextError
                              wrapper="div"
                              text="This is a clever token. Log in with Clever first"
                            />
                          )}
                          {cleverSignInError && (
                            <TextError
                              wrapper="div"
                              text="This is not a clever token"
                            />
                          )}
                          <h1 className="step-title text-center">
                            Step 1 of 3 : Redeem Your Subscription
                          </h1>
                          {Object.keys(errors).length > 0 && (
                            <div className="errors">
                              <div className="col-1 p-0 error-logo" />
                              <ul className="p-0 errors-list">
                                <ErrorMessage
                                  name="giftCode"
                                  component={() => (
                                    <TextError
                                      wrapper="li"
                                      text={errors.giftCode}
                                    />
                                  )}
                                />
                              </ul>
                            </div>
                          )}
                          <div className="step-info text-center">
                            {registerText[props.page].subTitleCode}
                          </div>
                          <div className="mx-0 my-3 row w-100">
                            <label
                              className="form-label col-form-label text-end p-0"
                              htmlFor="giftCode"
                            >
                              Subscription Code
                              <span className="form-required"> *</span>
                            </label>
                            <Field
                              className={`form-control ${errors.giftCode && "is-invalid"}`}
                              type="text"
                              name="giftCode"
                              placeholder="Paste your code here"
                            />
                          </div>
                          <div className="row button-redeem">
                            <div className="col-6 mx-auto mb-0 px-0 text-end">
                              <button
                                className="button btn-red-damask"
                                type="submit"
                                onClick={() => {
                                  handleClick("/gift/redeem/account-info");
                                }}
                              >
                                Use existing account
                              </button>
                            </div>
                            <div className="col-6 mx-auto mb-0 px-0">
                              <button
                                className="button btn-red-damask"
                                type="submit"
                                onClick={() => {
                                  handleClick("/gift/redeem/your-info");
                                }}
                              >
                                Create a new account
                              </button>
                            </div>
                          </div>
                          {showLoginClever && (
                            <div className="row button-redeem">
                              <div className="col-12 mx-auto mb-0 px-0 text-center">
                                <CleverSignIn
                                  isValidForm={isValidForm}
                                  waitForParent={isValidForm}
                                />
                              </div>
                            </div>
                          )}
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RedeemForm;
