import React from 'react'
import { Field, FormikErrors, FormikTouched } from "formik";

interface DropdownProps {
  isAdminPurchase: boolean;
  isCleverThemed: boolean;
  isButtonDisabled: boolean;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
}

export const PlanDropdowns: React.FC<DropdownProps> = ({
  isButtonDisabled,
  isAdminPurchase,
  isCleverThemed,
  errors,
  touched,
}) => {
  const containerClass = `${!isAdminPurchase ? "mx-0 w-100" : "admin-purchase-field"} ${isCleverThemed && 'd-flex justify-content-between'} my-3 row`
  return (
    <>
      <div className={containerClass}>
        <label
          className={`form-label col-form-label ${!isCleverThemed && 'text-lg-end'} p-0`}
          htmlFor="classroomPlan"
        >
          Classroom Plan <em>($179 {isCleverThemed && 'per classroom'})</em>
        </label>
        <Field
          as="select"
          name="classroomPlan"
          disabled={isButtonDisabled}
          className={`form-control form-select ${errors.classroomPlan && touched.classroomPlan && "is-invalid"}`}
        >
          <option value={0}>0</option>
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
          <option value={6}>6</option>
          <option value={7}>7</option>
          <option value={8}>8</option>
          <option value={9}>9</option>
          <option value={10}>10</option>
          {isCleverThemed && <option value={100}>100</option>}
        </Field>
      </div>
      <div className={containerClass}>
        <label
          className={`form-label col-form-label ${!isCleverThemed && 'text-lg-end'} p-0`}
          htmlFor="schoolPlan"
        >
          School Plan <em>($1450 {isCleverThemed && 'per 10 classrooms'})</em>
        </label>
        <Field
          as="select"
          name="schoolPlan"
          disabled={isButtonDisabled}
          className={`form-control form-select ${errors.schoolPlan && touched.schoolPlan && "is-invalid"}`}
        >
          <option value={0}>0</option>
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
          <option value={6}>6</option>
          <option value={7}>7</option>
          <option value={8}>8</option>
          <option value={9}>9</option>
          <option value={10}>10</option>
          {isCleverThemed &&
            <>
              <option value={100}>100</option>
              <option value={300}>300</option>
              <option value={500}>500</option>
              <option value={689}>689</option>
            </>
          }
        </Field>
      </div>
    </>
  )
}

