import { sendEvent } from "@api/googleAnalyticsService";
import {
  getParentRosters,
  getSchoolReports,
  getSchoolSubscriptions,
  getTeachers,
  userSignIn,
  verifyEmail,
} from "@api/rifService";
import CleverSignIn from "@components/Clever/CleverSignIn";
import TextError from "@components/TextError";
import TextSuccess from "@components/TextSuccess";
import { useCleverContext } from "@context/clever";
import { useUserContext } from "@context/user";
import { encodePassword, isCleverTheme } from "@utils/helpers";
import { ErrorMessage, Field, Form, Formik, FormikValues } from "formik";
import { sha256 } from "js-sha256";
import { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import * as Yup from "yup";

const SignIn = (props: {
  onSubmit: (arg0: boolean) => void;
  onRecovery: boolean;
  userName: string;
}) => {
  const validate = Yup.object({
    email: Yup.string().required("Username or email adress field."),
    password: Yup.string().required("Password field is required."),
  });
  const [signInError, setSignInError] = useState(false);
  const location: any = useLocation();
  const pageUrl = location.pathname;
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [migratoinError, setMigrationError] = useState(false);
  const userContext = useUserContext();
  const cleverContext = useCleverContext();
  const query = new URLSearchParams(location.search);
  const showLoginClever =
    process.env.REACT_APP_ENV_NAME === "local" ||
    query.get("clever-test") === "1";
  const isClever = useMemo(
    () => isCleverTheme(userContext, cleverContext),
    [userContext, cleverContext],
  );

  const [errorText, setErrorText] = useState(<></>);
  const [consumerAccount, setConsumerAccount] = useState(false);

  const handleSubmit = async (values: FormikValues) => {
    setSignInError(false);
    setButtonDisabled(true);
    setConsumerAccount(false);
    setErrorText(<></>);
    userContext.setFormProgress(50);

    const checkAccount = await verifyEmail(values.email);

    if (checkAccount.data.isRegistered) {
      if (checkAccount.data.consumerAccount) {
        setConsumerAccount(true);
        setErrorText(
          <div>
            Looking for Skybrary for Families?
            <a
              href="https://www.skybrary.org/user/sign-in"
              className="login-link"
            >
              Log in here
            </a>
          </div>,
        );
        setButtonDisabled(false);
        userContext.setFormProgress(100);
      } else {
        const emailLowercase = values.email.toLocaleLowerCase();
        const passwordEncrypted = sha256(
          `${emailLowercase}${values.password}*0*eDimMlW$dfzqO#o7%hnU@Uv&vBARs`,
        );
        const passwordEncoded = encodePassword(values.password);

        userSignIn(emailLowercase, passwordEncrypted, passwordEncoded).then(
          (data) => {
            if (data.status) {
              sendEvent({
                event: "login",
                eventProps: {
                  category: "form_submit",
                  action: "School user signs-in",
                  label: pageUrl,
                  value: "0",
                },
              });
              if (!data.data.userResponse.data.edu.user.userFirstLogin) {
                userContext.saveHasPosterRendered(true);
              }
              userContext.login(data.data);

              const token = data.data.auth.accessToken;
              const schoolId = data.data.userResponse.data.edu.user.rrkSchoolId;

              const requestConfig = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              };
              if (data.data.userResponse.data.edu.user.type === "parent") {
                getParentRosters(
                  data.data.userResponse.data.edu.user.rrkEduUserId,
                  requestConfig,
                ).then((parentReport) => {
                  userContext.saveParentReports(parentReport.data);
                });
                getSchoolSubscriptions(
                  requestConfig,
                  schoolId,
                  data.data.userResponse.data.edu.user.teacherRrkChildId,
                ).then((schoolSubscription) => {
                  userContext.saveSubscriptions(
                    schoolSubscription,
                    data.data.userResponse.data.edu.user,
                  );
                });
              }
              if (data.data.userResponse.data.edu.user.type === "admin") {
                getSchoolSubscriptions(requestConfig, schoolId, null).then(
                  (schoolSubscription) => {
                    userContext.saveSubscriptions(
                      schoolSubscription,
                      data.data.userResponse.data.edu.user,
                    );
                  },
                );
              } else if (
                data.data.userResponse.data.edu.user.type === "teacher"
              ) {
                getSchoolSubscriptions(
                  requestConfig,
                  schoolId,
                  data.data.userResponse.data.edu.user.rrkEduUserId,
                ).then((schoolSubscription) => {
                  userContext.saveSubscriptions(
                    schoolSubscription,
                    data.data.userResponse.data.edu.user,
                  );
                });
              }

              if (data.data.userResponse.data.type === "edu-admin") {
                getTeachers(requestConfig, "edu").then((teachers: any) => {
                  userContext.saveTeachers(
                    teachers,
                    data.data.userResponse.data.edu.user.relatedAccountId,
                    "admin",
                  );
                });
                getSchoolReports(requestConfig, schoolId).then(
                  (schoolReport) => {
                    userContext.saveSchoolReports(schoolReport);
                  },
                );
              } else if (data.data.userResponse.data.type === "edu-teacher") {
                const teachers = { data: [] };
                userContext.saveTeachers(teachers, null, "teacher");
                getSchoolReports(requestConfig, schoolId).then(
                  (schoolReport) => {
                    userContext.saveSchoolReports(schoolReport);
                  },
                );
              }
            } else {
              setSignInError(true);
              setButtonDisabled(false);
              setErrorText(
                <>
                  <span>{`Sorry, unrecognized username or password. `}</span>
                  <Link to={`/user/password?name=${values.email}`}>
                    Have you forgotten your password?
                  </Link>
                </>,
              );
            }
            props.onSubmit(data);
          },
        );
      }
    } else {
      userContext.setFormProgress(100);
      setSignInError(true);
      setButtonDisabled(false);
      setErrorText(
        <>
          <span>{`Sorry, unrecognized username or password. `}</span>
          <Link to={`/user/password?name=${values.email}`}>
            Have you forgotten your password?
          </Link>
        </>,
      );
    }
  };

  useEffect(() => {
    if (location.state !== null) {
      if (location.state?.errorSignIn) {
        setButtonDisabled(false);
        userContext.setFormProgress(100);
      }
      if (location.state?.migrationError) {
        setMigrationError(true);
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (localStorage.getItem("logoutMessage") !== null) {
      setErrorText(localStorage.getItem("logoutMessage"));
      setSignInError(true);
      setButtonDisabled(false);
      userContext.setFormProgress(100);
      localStorage.removeItem("logoutMessage");
    }
  }, []);

  return (
    <div>
      <h1 className="sign-in-title">User Sign In</h1>
      {signInError && <TextError text={errorText} wrapper="div" />}
      {migratoinError && (
        <TextError
          text="There was an error, We were not able to migrate the teacher"
          wrapper="div"
        />
      )}
      {props.onRecovery && (
        <TextSuccess
          text={
            "If " +
            props.userName +
            " is a valid account, an email will be sent with instructions to reset your password."
          }
        />
      )}
      {consumerAccount && <TextError text={errorText} wrapper="div" />}
      <div className="container p-4">
        <div className="row">
          <div className="">
            <div className="login-form px-2">
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={validate}
                onSubmit={handleSubmit}
                validateOnChange={false}
                validateOnBlur={false}
              >
                {({ errors }) => (
                  <Form>
                    {Object.keys(errors).length > 0 && (
                      <div className="errors">
                        <div className="error-logo" />
                        <ul className="errors-list">
                          <ErrorMessage
                            name="email"
                            component={() => (
                              <div className="error-message">
                                <TextError text={errors.email} wrapper="li" />
                              </div>
                            )}
                          />
                          <ErrorMessage
                            name="password"
                            component={() => (
                              <div className="error-message">
                                <TextError
                                  text={errors.password}
                                  wrapper="li"
                                />
                              </div>
                            )}
                          />
                        </ul>
                      </div>
                    )}
                    <div className="row justify-content-center">
                      <div className="form-wrapper">
                        <label className="label" htmlFor="email-signIn">
                          E-mail
                          <span className="form-required"> *</span>
                        </label>
                        <Field
                          className={`form-control email-form email-field ${errors.email && "is-invalid"}`}
                          type="email"
                          id="email-signIn"
                          name="email"
                        />
                        {!isClever && (
                          <div className="description">
                            Use your e-mail address.
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="form-wrapper">
                        <label className="label" htmlFor="password-signIn">
                          Password
                          <span className="form-required"> *</span>
                        </label>
                        <Field
                          className={`form-control password ${errors.password && "is-invalid"}`}
                          type="password"
                          id="password-signIn"
                          name="password"
                          placeholder="Password field is case sensitive"
                        />
                        {!isClever && (
                          <div className="description">
                            The password field is case sensitive.
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-sm-10 col-lg-4 offset-sm-1 offset-lg-4 mb-3 d-flex justify-content-center">
                      <Link
                        to="/user/password"
                        className="forgot-password mb-4"
                      >
                        {isClever ? (
                          <span className="label form-label">
                            Forgot your password?
                          </span>
                        ) : (
                          <span className="label form-label">
                            Have you forgotten your password?
                          </span>
                        )}
                      </Link>
                    </div>
                    <div className="d-grid col-9 mx-auto">
                      <div className="mx-auto mb-0 px-0 text-center">
                        <button
                          className={`button log-in-button ${isClever ? "btn-moderate-green" : "btn-di-serria"} btn-spinner-indicator`}
                          type="submit"
                          disabled={buttonDisabled}
                        >
                          Log in
                        </button>
                      </div>
                      {showLoginClever && isClever && (
                        <p className="sign-in-separator">
                          or sign in with district
                        </p>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
              {showLoginClever && (
                <div className="mx-auto mb-0 px-0 text-center">
                  <CleverSignIn isValidForm={true} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
