import { useLocation } from "react-router-dom";
import SkybraryLogo from "../../../images/logo-skybrary-school-bw.png";
import { useContext } from 'react'
import CleverContext from '../../../context/clever'

const OrderFormPdf = (props: any) => {
  const location: any = useLocation()
  const cleverContext: any = useContext(CleverContext);
  let isClever = cleverContext.isCleverUser();
  let userData = location.state.data;
  userData.email = userData.orderData.email


  const titleOneStatus = (isTitleOneStatus) => {
    let isTitleOne = ['Yes', 'No', `I don't know`]
    return isTitleOne[isTitleOneStatus]
  }

  const dateFormatted = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return  `${month}/${day}/${year}`
  }

  function classicUserLoginDataDisplay(email:string) {
    return (
      <>
        <div className="user-info">
          <p className="fw-normal">You will need your password to login, so please record it for your records.</p>
          <p className="fw-normal">Username<br/><b>{email}</b></p>
          <p className="fw-normal m-0">Password<br/>________________________</p>
        </div>
      </>
    )
  }

  function cleverUserLoginDataDisplay() {
    return (
      <>
        <div className="user-info">
          <p className="fw-normal">You will need your clever account to login.</p>
        </div>
      </>
    )
  }

  function schoolDataDisplay (userData: any, titleOneStatus: any)  {
    if(!userData || !userData.school) return null
    return (
      <>
        <p className="m-0 fw-normal">{userData.user.position}</p>
        <p className="m-0 fw-normal">{userData.school.name}</p>
        <br/>
        <p className="m-0 fw-normal">{userData.school.street}</p>
        <p className="m-0 fw-normal">{userData.school.city}, {userData.school.stateName} {userData.school.zipcode}</p>
        <p className="m-0 fw-normal">{userData.school.countryName}</p>
        <br/>
        <p className="m-0 fw-normal">{userData.school.phone}</p>
        <p className="m-0 fw-normal">{userData.email}</p>
        <br/>
        <p className="m-0 fw-normal">{userData.school.typeName}</p>
        <p className="m-0 fw-normal">Title 1 Status: {titleOneStatus(userData.school.isTitleOne)}</p>
      </>
    );
  }

  function skybraryOrderInstructions() {
    return <>
      <section className="instructions w-100 d-flex justify-content-between">
        <div>
          <img className="logo" src={SkybraryLogo}/>
        </div>
        <div className="how-to-order">
          <p className="fw-semibold subtitle">How to Order:</p>
          <ol>
            <li className="lh-1  m-0">Print two copies of this completed Order Form</li>
            <li className=" lh-1">
              Attach a signed Purchase Order or check to one of the Order Forms and send
              it to us. Checks payable to "READING IS FUNDAMENTAL". Order Form with attached Purchase Orders can be sent
              to:
              <br/>
              <br/>
              Fax: 202-536-3517
              <br/>
              Scan and Email: RIF_AR@rif.org
              <br/>
              Mail (required for Check)
              <br/>
              <p className="text-address">
                READING IS FUNDAMENTAL
                <br/>
                ATTN: Finance Department
                <br/>
                750 First St. NE #920
                <br/>
                Washington, DC 20002
              </p>
            </li>
            <li className="lh-1">Save the other copy of the Order Form for your records.</li>
          </ol>
          <p className="lh-1 m-0">
            We will do the rest! Once we receive your completed order form and validated it, we will activate your
            account and send you an email to the address provided, notifying you that your account is active.
          </p>
        </div>
      </section>
    </>
  }

  function userName(user: any, isClever: boolean) {
    if (isClever) {
      return <p className="m-0 fw-normal">Clever district admin: {user.firstName}{' '}{user.lastName}</p>
    } else {
      return <p className="m-0 fw-normal">{user.firstName}{' '}{user.lastName}</p>
    }
  }

  function loginDataDisplay(userData:any, isClever:boolean) {
    if (!isClever) {
      return classicUserLoginDataDisplay(userData.email)
    } else {
      return cleverUserLoginDataDisplay()
    }
  }

  function orderDetails(userData: any) {
    const classroomPlans = userData.orderData.classroomPlan;
    const schoolPlans = userData.orderData.schoolPlan;
    const renewals = userData.orderData.renewalsAmount;

    return (
      <section className="order-detail">
        <p className="fw-normal m-0 lh-1">Order Date: <b>{dateFormatted(userData.order.expirationDate)}</b></p>
        <br/>
        <table className="w-100">
          <thead>
            <tr>
              <th className="w-75 gray-background lh-1 fw-light">Product</th>
              <th className="gray-background lh-1 fw-light">Quantity</th>
              <th className="gray-background lh-1 fw-light">Price</th>
              <th className="gray-background lh-1 fw-light">Total</th>
            </tr>
          </thead>
          <tbody>
            {(classroomPlans > 0) &&
              (<tr>
                <td className="fw-normal lh-1">Classroom {classroomPlans === 1 ? "Plan" : "Plans"}</td>
                <td className="fw-normal lh-1">{classroomPlans}</td>
                <td className="fw-normal lh-1">$ {userData.orderData.classroomPlanPrice}</td>
                <td
                  className="fw-normal lh-1">$ {userData.orderData.classroomPlanPrice * classroomPlans}</td>
              </tr>)
            }
            {(schoolPlans > 0) &&
              (<tr>
                <td className="fw-normal">School {schoolPlans === 1 ? "Plan" : "Plans"}</td>
                <td className="fw-normal lh-1">{schoolPlans}</td>
                <td className="fw-normal lh-1">$ {userData.orderData.schoolPlanPrice}</td>
                <td className="fw-normal lh-1">$ {userData.orderData.schoolPlanPrice * schoolPlans}</td>
              </tr>)
            }
            {(renewals > 0) &&
              (<tr>
                <td className="fw-normal">Subscription {schoolPlans === 1 ? "Renewal" : "Renewals"}</td>
                <td className="fw-normal lh-1">{renewals}</td>
                <td className="fw-normal lh-1">$ {userData.orderData.classroomPlanPrice}</td>
                <td className="fw-normal lh-1">$ {userData.orderData.classroomPlanPrice * renewals}</td>
              </tr>)
            }
            {userData.orderData.promoCode &&
              (<tr>
                <td className="fw-normal lh-1">Promo Code ({userData.orderData.promoCode})</td>
                <td className="fw-normal lh-1">1</td>
                <td className="fw-normal lh-1">$ -{userData.orderData.discountCode}</td>
                <td className="fw-normal lh-1">$ -{userData.orderData.discountCode}</td>
              </tr>)
            }
          </tbody>
        </table>
        <br/>
        <p className="m-0 text-end">Order Total: <b>$ {userData.orderData.orderTotal.toFixed(2)}</b></p>
      </section>
    )
  }

  return (
    <div className="pdf-file" ref={props.refToAsign}>
      <header className="pdf-header d-flex">
        <h3 className="file-title w-50">Skybrary Order Form </h3>
        <p className="orderId fw-semibold">{`# ${userData.order.orderId}`}</p>
      </header>
      {skybraryOrderInstructions()}
      <section className="d-flex justify-content-between order-info">
        <div className="school-info">
          {userName(userData.user, isClever)}
          <br/>
          {!isClever && schoolDataDisplay(userData, titleOneStatus)}
        </div>
        {loginDataDisplay(userData, isClever)}
      </section>
      {orderDetails(userData)}
      <section className="terms-of-use text-center fw-normal">
        <p className="m-0 lh-1">
          Questions about your order? Need more information regarding volume pricing?
        </p>
        <p className="m-0 lh-1">
          Contact us at Skybrary@RIF.org
        </p>
        <p className="m-0 lh-1">
          By submitting this form, you agree to the terms and conditions in Skybrary for school Terms of Use.
        </p>
      </section>
    </div>
  )
}

export default OrderFormPdf;
