import TextSuccess from "../../TextSuccess";
import { useNavigate, useParams } from "react-router-dom";
import { verifyEmail } from "../../../api/rifService";
import { useContext } from "react";
import UserContext from "../../../context/user";

const OneTimeLogin = (props: { tokenVerification: any; queryString: any; }) => {  
  const params = useParams();  
  const navigate = useNavigate();  
  const userId = params.token;
  const userContext = useContext(UserContext);
  
  const resetPassword = async () => {
    const response = await verifyEmail(props.tokenVerification.data.data.emailAddress);
    let navigateState = {
      data: props.tokenVerification.data.data,
      token: props.queryString,
    }

    if (response.data.isEduParent && !response.data.firstLogin) {
      userContext.saveParentActivating();
      if(response.data.isTeacher || response.data.isConsumerParent)  {
        navigateState.activateTeacherParent = true;
        navigateState.userId = response.data.userId;
      } else {
        navigateState.activateParent = true;
      }
      
      navigate(`/set-password`, {
        state: navigateState,
      });
    } else if (!response.data.isTeacher || 
              response.data.eduTrial || 
              response.data.isEduParent || 
              ((response.data.isEduParent || response.data.isTeacher) && 
              response.data.firstLogin)) {
      navigate("/user/" + userId + "/edit-password", {
        state: navigateState,
      });
    } else {
      userContext.saveTeacherActivating();
      navigateState.activateTeacher = true;
      navigate(`/set-password`, {
        state: navigateState,
      });
    }    
  };

  return (
    <div className="sign-in-page">
      <div className="container">
        <TextSuccess
          text={"You have successfully validated your e-mail address."}
        />
        <div className="recovery-form pt-4">
          <p className="one-time-login-p">
            This is a one-time login to access Reading Is Fundamental the
            Skybrary School
          </p>
          <p className="one-time-login-p">
            Click on this button to log in to the site and start.
          </p>
          <p className="one-time-login-p">This login can be used only once.</p>
          <div className="d-grid col-2 mx-auto">
            <button
              className="button log-in-button btn btn-moderate-green btn-spinner-indicator"
              onClick={resetPassword}
            >
              Log in
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneTimeLogin;