import { render } from "@testing-library/react";
import { Field, FormikValues } from "formik";
import { useState, useEffect, useRef, useContext } from "react";
import { getLocations } from "../../../api/rifService";
import UserContext from "../../../context/user";
import { USA_LOCATION } from "../../../utils/constants";

interface BasicInfoProps {
  classes: string;
  errors: {
    school: string;
    street: string;
    city: string;
    country: string;
    state: string;
    zip: string;
    schoolPhone: string;
  };
  isValid: boolean;
  dirty: boolean;
  locations: [locations];
  values: FormikValues;
}

interface locations {
  countryId: number;
  name: string;
  states?: [state];
}
interface state {
  stateId: number;
  name: number;
}

const BasicInfo = (props: BasicInfoProps) => {
  const [locations, setLocations] = useState<any>(null);
  let statesList: state[] = [];
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const hasPreviousData = useContext(UserContext);

  useEffect(() => {
    getLocations().then(
      (response) => {
        setLocations(response.data);
        setIsLoaded(true);
      }
    ).catch((error) => {
      //By default we will show USA and its states in case the api fails.
      setLocations(USA_LOCATION);
      setIsLoaded(true);
    })
  }, []);

  let countryId;
  if (props.values.countryId) {
    countryId = props.values.countryId;
  } else {
    countryId = props.values.country;
  }

  const renderCountries = (
    locations: locations,
    isLoaded: boolean,
    error: any
  ) => {
    if (error) {
      return <option>{error.message}</option>;
    } else if (!isLoaded) {
      return <option>Loading...</option>;
    } else {
      return locations.data?.map((country, index: number) => {
        return (
          <option value={country.countryId} key={index}>
            {country.name}
          </option>
        );
      });
    }
  };

  const alphabeticSort = (array: any) => {
    return array.sort((a: any, b: any) => (a.props.children > b.props.children ? 1 : -1));
  };

  const renderStates = (countryIdSelected: number) => {
    let countrySelected = locations?.data?.find(
      (country) => country.countryId == countryIdSelected
    );
    
    if (countrySelected?.states) {
      statesList = countrySelected?.states.map(function (state, index) {
        if (state.stateId === props.values.stateId) {
          return (
            <option value={state.stateId} key={index} defaultValue>
              {state.name}
            </option>
          );
        } else {
          return (
            <option value={state.stateId} key={index}>
              {state.name}
            </option>
          );
        }
      });
    }

    statesList = alphabeticSort(statesList)
    
    return statesList;
  };

  return (
    <>
      <div className={props.classes}>
        <label
          className="form-label col-form-label p-0"
          htmlFor="school"
        >
          School
          <span className="form-required"> *</span>
        </label>
        <Field
          className={`form-control ${props.errors.school && "is-invalid"}`}
          type="text"
          name="school"
          placeholder=""
        />
        <div className="description pe-0">Letters and numbers are allowed</div>
      </div>
      <div className={props.classes}>
        <label
          className="form-label col-form-label p-0"
          htmlFor="street"
        >
          Street
          <span className="form-required"> *</span>
        </label>
        <Field
          className={`form-control ${props.errors.street && "is-invalid"}`}
          type="text"
          name="street"
          placeholder=""
        />
        <div className="description pe-0">Letters and numbers are allowed</div>
      </div>
      <div className={props.classes}>
        <label
          className="form-label col-form-label p-0"
          htmlFor="city"
        >
          City
          <span className="form-required"> *</span>
        </label>
        <Field
          className={`form-control ${props.errors.city && "is-invalid"}`}
          type="text"
          name="city"
          placeholder=""
        />
        <div className="description pe-0">Name of your city</div>
      </div>
      <div className={props.classes}>
        <label
          className="form-label col-form-label p-0"
          htmlFor="country"
        >
          Country
          <span className="form-required"> *</span>
        </label>
        <Field as="select" className="form-control form-select" name="country">
          <option value="">Select</option>
          {renderCountries(locations, isLoaded, error)}
        </Field>
        <div className="description pe-0">Select a Country</div>
      </div>
      <div className={props.classes}>
        <label
          className="form-label col-form-label p-0"
          htmlFor="state"
        >
          State/Province
          <span className="form-required"> *</span>
        </label>
        <Field
          as="select"
          className="form-control form-select"
          name="state"
          disabled={!props.dirty}
        >
          {renderStates(countryId)}
        </Field>
        <div className="description pe-0">Select a State</div>
      </div>
      <div className={props.classes}>
        <label className="form-label col-form-label p-0" htmlFor="zip">
          Zip Code/Postal Code
          <span className="form-required"> *</span>
        </label>
        <Field
          className={`form-control ${props.errors.zip && "is-invalid"}`}
          type="text"
          name="zip"
          placeholder=""
        />
        <div className="description pe-0">Letters and numbers are allowed</div>
      </div>
      <div className={props.classes}>
        <label
          className="form-label col-form-label p-0"
          htmlFor="schoolPhone"
        >
          School Phone
          <span className="form-required"> *</span>
        </label>
        <Field
          className={`form-control ${props.errors.schoolPhone && "is-invalid"}`}
          type="text"
          name="schoolPhone"
          placeholder=""
        />
        <div className="description pe-0">
          Numbers, dashes and spaces are allowed
        </div>
      </div>
      <div className={props.classes}>
        <label
          className="form-label col-form-label p-0"
          htmlFor="schoolType"
        >
          School Type
          <span className="form-required"> *</span>
        </label>
        <Field
          as="select"
          className="form-control form-select"
          name="schoolType"
        >
          <option value={1}>Public School</option>
          <option value={2}>Private School</option>
          <option value={3}>Charter School</option>
          <option value={4}>Parochial/Religious School</option>
          <option value={5}>Home School</option>
          <option value={6}>Other</option>
        </Field>
        <div className="description pe-0">Select a School Type</div>
      </div>
      <div className={props.classes}>
        <label
          className="form-label col-form-label p-0"
          htmlFor="schoolType"
        >
          Title 1 school?
          <span className="form-required"> *</span>
        </label>
        <Field
          as="select"
          className="form-control form-select"
          name="isTitleOne"
        >
          <option value="0">Yes</option>
          <option value="1">No</option>
          <option value="2">I don't know</option>
        </Field>
      </div>
    </>
  );
};

export default BasicInfo;
