import { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { studentLogIn } from "../../../api/rifService";
import { sendEvent } from "../../../api/googleAnalyticsService";
import UserContext from "../../../context/user";
import PasswordForm from "../PasswordForm";

const LogInForm = (props: {
  userToken: string | undefined;
  showError: any;
  data: any;
}) => {
  const userContext = useContext(UserContext);
  const [password, setPassword] = useState({ password: "", type: "" });

  const [buttonDisabled, setButtonDisabled] = useState(false);

  let location = useLocation();
  const pageUrl = location.pathname;

  const handleSubmit = async () => {
    props.showError({ show: false, message: "" });
    setButtonDisabled(true);
    userContext.setFormProgress(50);

    if (password.password === "") {
      props.showError({ show: true, message: "Your password is empty" });
      setButtonDisabled(false);
      userContext.setFormProgress(0);
    } else {
      let data = {
        clean_pass: password.password,
        pass_type: password.type,
      };

      if (password.type === "icon") {
        data.clean_pass = '';
        if (password.password[0] !== undefined) {
          data.clean_pass += password.password[0];
        }
        if (password.password[1] !== undefined) {
          data.clean_pass += password.password[1];
        }
      }

      const response = await studentLogIn(props.userToken, data);

      if (response.status) {
        sendEvent({
          'event': 'login','eventProps': {
            'category': 'form_submit',
            'action': 'Student signs-in',
            'label': pageUrl,
            'value': '0'
          }
        });
        response.data.user.parentToken = props.data.hash
        userContext.logout();
        userContext.loginStudent(response.data);
        window.location.replace(process.env.REACT_APP_WEBAPP_DOMAIN as string);

        setButtonDisabled(true);
        userContext.setFormProgress(50);
      } else {
        props.showError({
          show: true,
          message: response.data.response.data?.errorMessage ?? "",
        });
        setButtonDisabled(false);
        userContext.setFormProgress(0);
      }
    }
  };

  const handlePassword = (data: { password: string; type: string }) => {
    setPassword({
      password: data.password,
      type: data.type
    });
  };

  return (
    <div className="parent-log-in text-center mt-4">
      <PasswordForm
        password={{
          passwordType: "",
        }}
        from="parents"
        onSubmit={handleSubmit}
        onPasswordChange={handlePassword}
        buttonState={buttonDisabled}
      />
      <div className="button-wrapper mt-2">
        <Link
          className="links mt-2"
          to="/sigin/forgot-password"
          state={{ userToken: props.userToken, data: props.data }}
        >
          Forgot your Password
        </Link>
        <Link
          className="links"
          to={`/sigin/change-password/${props.userToken}`}
          state={{ classHash: props.data.hash, data: props.data }}
        >
          Change your Password
        </Link>
      </div>
    </div>
  );
};

export default LogInForm;
