import Header from "../../header/Header";
import { ErrorMessage, Field, Form, Formik, FormikValues } from "formik";
import TextError from "../../TextError";
import * as Yup from "yup";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {useContext, useEffect, useRef} from "react";
import GiftContext from "../../../context/Forms/giftContext";
import { BACK_URL, registerText } from "../../../utils/constants";
import CleverContext from '../../../context/clever'
import UserContext from "../../../context/user";
import {isCleverTheme} from "../../../utils/helpers";

const GiftForm = (props: { formSteps: any, showHeader: boolean }) => {
  const giftContext = useContext(GiftContext);
  const cleverContext = useContext(CleverContext)
  const userContext = useContext(UserContext)
  const navigate = useNavigate();
  let previousPath = BACK_URL;
  let buttonText = "<< Back";

  if (giftContext.prevUrl) {
    previousPath = giftContext.prevUrl;
    buttonText = "<< Back to Dashboard";
  }

  const schemaValidate = Yup.object({
    buyerFirstName: Yup.string()
      .required("First name field is required.")
      .min(1)
      .max(128)
      .trim(),
    buyerLastName: Yup.string()
      .required("Last name field is required.")
      .min(1)
      .max(128)
      .trim(),
    buyerEmail: Yup.string()
      .required("Email Address field.")
      .email("The email entered is not valid."),
    buyerReEnteredEmail: Yup.string()
      .required("Re-enter Email Address field is required.")
      .email("The re-entered email address is not valid."),
    recipientFirstName: Yup.string()
      .required("First name field is required.")
      .min(1)
      .max(128)
      .trim(),
    recipientLastName: Yup.string()
      .required("Last name field is required.")
      .min(1)
      .max(128)
      .trim(),
    recipientEmail: Yup.string()
      .required("Email Address field.")
      .email("The email entered is not valid."),
    reEnteredEmailRecipient: Yup.string()
      .required("Re-enter Email Address field is required.")
      .email("The re-entered email address is not valid."),
    agreement: Yup.boolean().oneOf(
      [true],
      "Please agree to the Terms of Use below"
    ),
  });

  const handleSubmit = (values: FormikValues) => {
    giftContext.setForms({ values: values, form: 1 });

    if (isCleverTheme(userContext, cleverContext)) {
      navigate("/teachers/gift/checkout")
    } else {
      navigate("/gift/checkout")
    }
  };

  useEffect(() => {
    document.title = "Give A Gift | Reading Is Fundamental";
  }, []);

  return (
    <>
      {props.showHeader && (
        <Header
          steps={props.formSteps}
          activeStep={1}
          previousPath={previousPath}
          buttonText={buttonText}
        />
      )}
      <div className="register-form mx-auto">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="login-form">
                <Formik
                  initialValues={{
                    buyerFirstName: cleverContext.isCleverUser() ? userContext.userData.edu.user.firstName : "",
                    buyerLastName: cleverContext.isCleverUser() ? userContext.userData.edu.user.lastName : "",
                    buyerEmail: cleverContext.isCleverUser() ? userContext.userData.emailAddress : "",
                    buyerReEnteredEmail: cleverContext.isCleverUser() ? userContext.userData.emailAddress : "",
                    recipientFirstName: "-",
                    recipientLastName: "-",
                    recipientEmail: "",
                    reEnteredEmailRecipient: "",
                    agreement: false,
                  }}
                  validationSchema={schemaValidate}
                  onSubmit={handleSubmit}
                  validateOnChange={false}
                  validateOnBlur={false}
                  enableReinitialize={true}
                >
                  {({ errors }) => (
                    <Form>
                      <h1 className="step-title text-center">
                        {registerText.gift.purchaseStepsText[0]}
                      </h1>
                      {Object.keys(errors).length > 0 ? (
                        <div className="errors">
                          <div className="col-1 p-0 error-logo" />
                          <ul className="p-0 errors-list">
                            <ErrorMessage
                              name="buyerFirstName"
                              component={() => (
                                <TextError
                                  wrapper="li"
                                  text={errors.buyerFirstName}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="buyerLastName"
                              component={() => (
                                <TextError
                                  wrapper="li"
                                  text={errors.buyerLastName}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="buyerEmail"
                              component={() => (
                                <TextError
                                  wrapper="li"
                                  text={errors.buyerEmail}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="buyerReEnteredEmail"
                              component={() => (
                                <TextError
                                  wrapper="li"
                                  text={errors.buyerReEnteredEmail}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="recipientFirstName"
                              component={() => (
                                <TextError
                                  wrapper="li"
                                  text={errors.recipientFirstName}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="recipientLastName"
                              component={() => (
                                <TextError
                                  wrapper="li"
                                  text={errors.recipientLastName}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="recipientEmail"
                              component={() => (
                                <TextError
                                  wrapper="li"
                                  text={errors.recipientEmail}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="reEnteredEmailRecipient"
                              component={() => (
                                <TextError
                                  wrapper="li"
                                  text={errors.reEnteredEmailRecipient}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="agreement"
                              component={() => (
                                <TextError
                                  wrapper="li"
                                  text={errors.agreement}
                                />
                              )}
                            />
                          </ul>
                        </div>
                      ) : null}
                      {!isCleverTheme(userContext, cleverContext) && (
                      <div className="step-info text-center">
                        <span>
                          Give educators & their students the gift of reading &
                          learning with a 12-month subscription <br /> to
                          Skybrary for school!
                        </span>
                        <br />
                        <br />
                        <strong>Our Plans:</strong>
                        <br />
                        <span>
                          CLASSROOM PLAN ($179): Provides up to 35 seats or
                          individual logins per class. <br /> SCHOOL PLAN ($1450): A
                          bundle of 10 Classroom Plans for a discounted price.
                          Each provides up to 35 <br /> seats per class, for a total of
                          350 individual logins. *Savings of $340
                        </span>
                        <br />
                        <br />
                        <strong>All plans include:</strong>
                        <br />
                        <span>
                          Unlimited access to hundreds of
                          books and video field trips <br /> Teacher portal with 40
                          themed units & easy-to-use teacher management tools <br />
                          Individual logins to access the service at school and
                          at home <br /> Reading reports to track student reading &
                          viewing time
                        </span>
                        <br />
                        <br />
                        <br />
                        <span>
                          Need more? For larger volume or district purchases,
                          contact Skybrary@RIF.org.
                        </span>
                        <br />
                        <br />
                        <span>
                          To get started, we just need a little bit of info
                          about you and the gift recipient.
                        </span>
                        <br />
                      </div>
                      )}
                      {!isCleverTheme(userContext, cleverContext) && (
                      <div className="yourInformation mt-5">
                        <legend className="subtitle-wrapper mb-3">
                          <span>Your Information</span>
                          <div className="bar"></div>
                        </legend>
                        <div className="mx-0 my-3 row w-100">
                          <label
                            className="form-label col-form-label p-0"
                            htmlFor="firstName"
                          >
                            First Name
                            <span className="form-required"> *</span>
                          </label>
                          <Field
                            className={`form-control ${
                              errors.buyerFirstName && "is-invalid"
                            }`}
                            type="text"
                            name="buyerFirstName"
                          />
                        </div>
                        <div className="mx-0 my-3 row w-100">
                          <label
                            className="form-label col-form-label p-0"
                            htmlFor="lastName"
                          >
                            Last Name
                            <span className="form-required"> *</span>
                          </label>
                          <Field
                            className={`form-control ${
                              errors.buyerLastName && "is-invalid"
                            }`}
                            type="text"
                            name="buyerLastName"
                          />
                        </div>
                        <div className="mx-0 my-3 row w-100">
                          <label
                            className="form-label col-form-label p-0"
                            htmlFor="email"
                          >
                            Email Address
                            <span className="form-required"> *</span>
                          </label>
                          <Field
                            className={`form-control ${
                              errors.buyerEmail && "is-invalid"
                            }`}
                            type="email"
                            name="buyerEmail"
                          />
                          <span className="description">
                            Where we will send the receipt
                          </span>
                        </div>
                        <div className="mx-0 my-3 row w-100">
                          <label
                            className="form-label col-form-label p-0"
                            htmlFor="reEnteredEmail"
                          >
                            Re-enter Email Address
                            <span className="form-required"> *</span>
                          </label>
                          <Field
                            className={`form-control ${
                              errors.buyerReEnteredEmail && "is-invalid"
                            }`}
                            type="email"
                            name="buyerReEnteredEmail"
                          />
                        </div>
                      </div>
                      )}
                      <div className="recipientInformation">
                        <legend className="subtitle-wrapper mb-3">
                          <span>Recipient Information</span>
                          <div className="bar"></div>
                        </legend>
                        {!isCleverTheme(userContext, cleverContext) && (
                        <>
                        <div className="mx-0 my-3 row w-100">
                          <label
                            className="form-label col-form-label p-0"
                            htmlFor="firstNameRecipient"
                          >
                            First Name
                            <span className="form-required"> *</span>
                          </label>
                          <Field
                            className={`form-control ${
                              errors.recipientFirstName && "is-invalid"
                            }`}
                            type="text"
                            name="recipientFirstName"
                          />
                        </div>
                        <div className="mx-0 my-3 row w-100">
                          <label
                            className="form-label col-form-label p-0"
                            htmlFor="lastNameRecipient"
                          >
                            Last Name
                            <span className="form-required"> *</span>
                          </label>
                          <Field
                            className={`form-control ${
                              errors.recipientLastName && "is-invalid"
                            }`}
                            type="text"
                            name="recipientLastName"
                          />
                        </div>
                        </>
                        )}
                        <div className={`mx-0 my-3 row w-100 ${isCleverTheme(userContext, cleverContext) && 'd-flex justify-content-between'}`}>
                          <label
                            className={`form-label col-form-label p-0 ${isCleverTheme(userContext, cleverContext) && 'text-start '}`}
                            htmlFor="emailRecipient"
                          >
                            Email Address
                            <span className="form-required"> *</span>
                          </label>
                          <Field
                            className={`form-control gift-info-field ${errors.recipientEmail && "is-invalid"}`}
                            type="email"
                            name="recipientEmail"
                          />
                          <span className="description">
                            Where we will send the receipt
                          </span>
                        </div>
                        <div className={`mx-0 my-3 row w-100 ${isCleverTheme(userContext, cleverContext) && 'd-flex justify-content-between'}`}>
                          <label
                            className={`form-label col-form-label p-0 ${isCleverTheme(userContext, cleverContext) && 'text-start '}`}
                            htmlFor="reEnteredEmailRecipient"
                          >
                            Re-enter Email Address
                            <span className="form-required"> *</span>
                          </label>
                          <Field
                            className={`form-control gift-info-field ${errors.reEnteredEmailRecipient && "is-invalid"}`}
                            type="email"
                            name="reEnteredEmailRecipient"
                          />
                        </div>
                        <div className="mx-auto my-3 row w-100 terms-of-service text-center">
                          <div className="w-100 px-0">
                            <Field
                              className="form-check-input my-auto p-0 align-middle"
                              type="checkbox"
                              name="agreement"
                              id="agreement"
                            />
                            <label
                              htmlFor="agreement"
                              className="align-middle col-form-label form-check-label p-0 d-inline"
                            >
                              Reading Is Fundamental{" "}
                              <a
                              className="tos-link"
                              href="https://skybrary.org/school/terms-of-use"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Terms of Use
                            </a>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="text-center col-12 mx-auto mb-0">
                        <button className={`btn ${isCleverTheme(userContext, cleverContext) ? 'btn-sky btn-moderate-green btn-sky-lg' : 'btn-submit'}`} type="submit">
                          {`Next >>`}
                        </button>
                      </div>
                      <div className="mx-auto my-4 row w-100 privacy-policy text-center">
                        <div className="w-100 px-0">
                          <label
                            htmlFor="agreement"
                            className="align-middle p-0 d-inline"
                          >
                            Reading Is Fundamental{" "}
                            <a
                              className="existing-account-text ps-1"
                              href="https://skybrary.org/school/privacy-policy"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Privacy Policy
                            </a>
                          </label>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GiftForm;
