import Header from "../../../header/Header";
import { Field, Form, Formik, FormikValues } from "formik";
import { useNavigate } from "react-router-dom";
import { formSteps } from "../../../../context/Forms/purchaseContext";
import { useContext, useEffect, useState } from "react";
import UserContext from "../../../../context/user";
import MaxModal from "../../purchase/Modals/PromoModal";
import {redeemGift,
  redeemCleverGift,
  validateGiftToken
} from "../../../../api/rifService";
import {utcToZonedTime} from "date-fns-tz";
import { getDate, getMonth, getYear } from "date-fns";
import CleverContext from '../../../../context/clever'
import SubmitButton from "@components/Buttons/SubmitButton";

const SubscriptionInfoForm = (props: { page: string }) => {
  let path = "";
  let expirationDate;
  const navigate = useNavigate();
  const cleverContext = useContext(CleverContext)
  const userContext = useContext(UserContext);

  let subscriptions: any = userContext.subscriptions;
  const userData = userContext.userData;
  const maxSubscriptions = localStorage.getItem('giftMaxSubscriptions');
  const giftToken = cleverContext.getCleverGiftToken()

  const [giftExpirationDate, setGiftExpirationDate] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [subscriptionsToRenew, setSubscriptionsToRenew] = useState<string[]>([]);
  const [prevPath, setPrevPath] = useState("");
  const [setErrorredeem, setErrorRedeem] = useState(false);
  const [maxModal, setMaxModal] = useState({
    show: false,
    class: "",
    onClick: () => {},
    body: <div />,
  });

  const handleClosePromo = () => {
    setMaxModal({
      show: false,
      class: "",
      onClick: () => {},
      body: <div />,
    });
  };

  const handleSubmit = async (values: FormikValues) => {
    setErrorRedeem(false);
    userContext.setFormProgress(50);
    const accountData = {
      token: giftToken,
      newAccount: false,
      subscriptionsRenewals: subscriptionsToRenew,
    }

    const response = cleverContext.giftForClever()
      ? await redeemCleverGift(userContext.token, accountData)
      : await redeemGift(userContext.token, accountData, true)

    if (response) {
      userContext.setFormProgress(100);
      navigate(path, {state: {showSuccess: true}});
    } else {
      setErrorRedeem(true);
      setButtonDisabled(false)
      userContext.setFormProgress(100);
    }
  };

  const getGiftExpirationInfo = async () => {
    const giftInformation = await validateGiftToken(giftToken);
    setGiftExpirationDate(giftInformation.gift.expirationDate)
  };

  const formatDate = (date: number | string ) => {
    if (date) {
      expirationDate = utcToZonedTime(
        new Date(date),
        "UTC"
      );
    } else {
      expirationDate = new Date(giftExpirationDate);
    }

    let yearVal = getYear(expirationDate);
    let dateFields = {
      year: yearVal,
      month: getMonth(expirationDate) + 1,
      day: getDate(expirationDate),
    };
    return `${dateFields.month < 10 ? "0" : ""}${dateFields.month}/${dateFields.day < 10 ? "0" : ""}${dateFields.day}/${yearVal}`;
  };

  const handleSubscription = (sub: any) => {
    if (subscriptionsToRenew.includes(sub)) {
      setSubscriptionsToRenew((subscriptionsToRenew: string[]) =>
        subscriptionsToRenew.filter((item) => item !== sub)
      );
    } else {
      if (subscriptionsToRenew.length < maxSubscriptions) {
        setSubscriptionsToRenew((subscriptionsToRenew: string[]) => [
          ...subscriptionsToRenew,
          sub,
        ]);
      } else {
        document.getElementById(sub).checked = false;
        setMaxModal({
          show: true,
          class: "modal-promo",
          onClick: handleClosePromo,
          body: <h2>You dont have more subscriptions to renew</h2>,
        });
      }
    }
  };

  const renderSubscriptions = () => {
    return subscriptions.map((sub: any, index: number) => {
      return (
        <div className="subscriptions" key={index}>
          <label className="subscription">
            <Field
              className="form-check-input"
              type="checkbox"
              name={sub.rrkSubscriptionEduId}
              id={sub.rrkSubscriptionEduId}
              onClick={() => handleSubscription(sub.rrkSubscriptionEduId)}
            />
            <>
              <h1 className="sub-name">{sub.className}</h1>
              <p className="sub-expiration">
                {"Expires at "}
                <span className="date">
                  {formatDate(sub.expirationDate)}
                </span>
              </p>
              <p className="sub-expiration">
                {"New expiration date "}
                <span className="date">
                  {formatDate("")}
                </span>
              </p>
            </>
          </label>
        </div>
      );
    });
  };

  useEffect (() => {
    document.title = "School Info | Reading Is Fundamental";
    if ((maxSubscriptions == null) || (giftToken  == null)) {
      navigate('/gift/redeem/manual')
    }
    if (!userContext.token) {
      navigate('/gift/redeem/account-info')
    }
    if (userData.edu.user.isTrial) {
      setPrevPath("/teachers/dashboard");
    } else {
      setPrevPath("/adm/dashboard");
    }
    getGiftExpirationInfo();
  }, []);

  return (
    <>
      <Header
        steps={formSteps.redeem_logged}
        activeStep={3}
        previousPath={prevPath}
        buttonText="<< Back"
      />
      <div className="register-form subscription-info mx-auto">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="login-form">
                <Formik
                  onSubmit={handleSubmit}
                  validateOnChange={false}
                  validateOnBlur={false}
                  initialValues={{
                    giftCode: "",
                  }}
                >
                  {() => (
                    <Form>
                      <h1 className="step-title text-center">
                        Step 3 of 3 : Subscriptions
                      </h1>
                      <div className="step-info text-center">
                        You have been gifted with {maxSubscriptions}
                        {maxSubscriptions === "1" ? " subscription" : " subscriptions"}
                      </div>
                      <div className="step-info text-center mt-3">
                        Please select the subscription(s) you would like to
                        renew or just add new subscription to your account.
                      </div>

                      {subscriptions.length > 0 && (
                        <div className="mx-0 my-3 row w-100 subscription-list">
                          <fieldset className="fs-renew">
                            <legend className="legend-renew">
                              Click to renew your subscriptions
                            </legend>
                            <h2 className="fieldset-sub-title">
                              Select the subscriptions to renew
                            </h2>
                            {renderSubscriptions()}
                          </fieldset>
                        </div>
                      )}

                      <h1 className="step-title text-center second-title">
                        Redemption Status
                      </h1>
                      <p className="info-subscriptions-selected">
                        This is how you have selected to use your{" "}
                        {maxSubscriptions} subscriptions.
                      </p>
                      <div>
                        {subscriptionsToRenew.length < maxSubscriptions && (
                          <p className="new-subscriptions">
                            {Math.abs(
                              subscriptionsToRenew.length - maxSubscriptions
                            )}{" "}
                            new subscriptions
                          </p>
                        )}
                        {subscriptionsToRenew.length !== 0 && (
                          <p className="new-subscriptions">
                            {subscriptionsToRenew.length} renewed
                            {subscriptionsToRenew.length === 1 ? " subscription" : " subscriptions"}
                          </p>
                        )}
                      </div>

                      <div className="row button-redeem">
                        <div className="col-12 mx-auto mb-0 px-0 text-center">
                          <SubmitButton
                            isClever={false}
                            onClick={() => { path = "/gift/redeem/success" }}
                            isDisabled={buttonDisabled}
                            text="Complete"
                          />
                        </div>
                      </div>

                    </Form>
                  )}
                </Formik>
                <MaxModal
                  show={maxModal.show}
                  class={maxModal.class}
                  onClick={maxModal.onClick}
                  body={maxModal.body}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionInfoForm;
