import { Formik, Form, Field, ErrorMessage, FormikValues } from "formik";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import TextError from "../../TextError";
import { FormPurchaseContext } from "../../../context/Forms/purchaseContext";
import Header from "../../header/Header";
import { useNavigate, useLocation } from "react-router";
import BasicInfo from "./BasicInfo";
import UserContext from "../../../context/user";
import { BACK_URL, registerText } from "../../../utils/constants";
import {
  createUserSubscription,
  redeemGift,
} from "../../../api/rifService";
import GiftContext from "../../../context/Forms/giftContext";
import { decodeValue } from "../../../utils/helpers";
import { sendEvent } from "../../../api/googleAnalyticsService";

type values = {
  firstName: string;
  lastName: string;
  position: string;
  school: string;
  street: string;
  city: string;
  country: number;
  state: number;
  zip: string;
  schoolPhone: number;
  schoolType: number;
  isTitleOne: string;
};

const FormSchoolInfo = (props: any) => {
  const schoolContext = useContext(FormPurchaseContext);
  const giftContext = useContext(GiftContext);
  const navigate = useNavigate();
  let previousPath = BACK_URL;
  let userContext = useContext(UserContext);
  let active = 2;
  const initialValues = {
    firstName: "",
    lastName: "",
    position: "",
    school: "",
    street: "",
    city: "",
    country: "",
    state: 0,
    zip: "",
    schoolPhone: "",
    schoolType: 1,
    isTitleOne: "2",
  };
  let buttonText = `Next >>`;

  useEffect(() => {
    if (props.page === "try-it-free") {
      document.title = "Teachers, Try It Free | Reading Is Fundamental";
    }
    if (props.page === "redeem") {
      document.title = "School Info | Reading Is Fundamental";
    }
  }, []);

  const [submitSuccess, setSubmitSuccess] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  if (props.page === "redeem") {
    active = 3;
  }
  if (props.page === "try-it-free") {
    buttonText = "COMPLETE";
  }

  const schemaValidate = Yup.object({
    firstName: Yup.string().required("First name field is required.").max(128),
    lastName: Yup.string().required("Last name field is required.").max(128),
    position: Yup.mixed().required("Position field is required."),
    school: Yup.string().required("School field is required.").max(128),
    street: Yup.string().required("Street field is required.").max(128),
    city: Yup.string().required("City field is required.").max(128),
    country: Yup.number().required("Country field is required."),
    state: Yup.number(),
    zip: Yup.string()
      .required("Zip Code/Postal Code field is required.")
      .max(128),
    schoolPhone: Yup.string()
      .max(128)
      .required("School Phone field is required.")
      .matches(
        /^[0-9+() X-]*$/,
        "Numbers, dashes and spaces are allowed. e.g. 1 1(519) 555-4444"
      ),
  });

  const createSubscription = async (data) => {
    const response = await createUserSubscription(data);
    if (response.status) {
      userContext.setFormProgress(100);
      const path = `/${props.page}/success`;
      localStorage.clear();
      navigate(path, { replace: true, state: { showSuccess: true } });
    } else {
      userContext.setFormProgress(100);
      setSubmitSuccess(false);
      setButtonDisabled(false);
    }
  };

  let location = useLocation();
  const pageUrl = location.pathname;

  const handleSubmit = async (values: FormikValues) => {
    setButtonDisabled(true);
    setSubmitSuccess(true);
    let path;
    let data = {
      firstName: values.firstName,
      lastName: values.lastName,
      schoolName: values.school,
      position: values.position,
      street: values.street,
      countryId: parseInt(values.country),
      stateId: parseInt(values.state),
      city: values.city,
      zipcode: values.zip,
      phone: values.schoolPhone,
      typeId: values.schoolType,
      isTitleOne: values.isTitleOne,
    };
    schoolContext.setSecondStep(data);
    if (props.page === "purchase") {
      path = `/${props.page}/checkout`;
      sendEvent({
        'event': 'add_user_info',
        'eventProps': {
          'category': 'form_submit',
          'action': '',
          'label': pageUrl,
          'value': '0'
          }
      });
      navigate(path, { replace: true });
    } else if (props.page === "redeem") {
      path = "/gift/redeem/success";

      let giftToken = JSON.parse(localStorage.getItem('giftToken'))

      const accountData = {
        token: giftToken,
        school: {
          name: values.school,
          street: values.street,
          countryId: parseInt(values.country),
          stateId: parseInt(values.state),
          city: values.city,
          phone: values.schoolPhone,
          zipcode: values.zip,
          typeId: values.schoolType,
          isTitleOne: values.isTitleOne,
        },
        user: {
          firstName: values.firstName,
          lastName: values.lastName,
          emailAddress: schoolContext.registerData.email,
          password: decodeValue(schoolContext.registerData.password),
          position: values.position,
          optIn: true,
        },
        newAccount: true,
      };

      const response = await redeemGift(0, accountData, false);
      if (response) {
        navigate(path, { replace: true, state: { showSuccess: true } });
      } else {
        setButtonDisabled(false);
        setSubmitSuccess(false);
      }
    } else {
      const subscriptionData = {
        school: {
          name: values.school,
          street: values.street,
          countryId: parseInt(values.country),
          stateId: parseInt(values.state),
          city: values.city,
          phone: values.schoolPhone,
          zipcode: values.zip,
          typeId: values.schoolType,
          isTitleOne: values.isTitleOne,
        },
        user: {
          firstName: values.firstName,
          position: values.position,
          lastName: values.lastName,
          emailAddress: schoolContext.registerData.email,
          password: decodeValue(schoolContext.registerData.password),
          optIn: true,
        },
        type: "trial",
      };
      path = `/${props.page}/success`;
      userContext.setFormProgress(50);
      sendEvent({
        'event': 'add_user_info',
        'eventProps': {
          'category': 'form_submit',
          'action': 'School user adds their info',
          'label': pageUrl,
          'value': '0'
          }
      });
      createSubscription(subscriptionData);
    }
  };

  return (
    <>
      <Header
        steps={props.formSteps}
        activeStep={active}
        previousPath={previousPath}
        buttonText="<< Back"
      />
      <div className="register-form mx-auto">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="login-form">
                {!submitSuccess && (
                  <TextError
                    wrapper="div"
                    text="Something went wrong, try again"
                  />
                )}
                <Formik
                  initialValues={initialValues}
                  validationSchema={schemaValidate}
                  onSubmit={handleSubmit}
                  validateOnChange={false}
                  validateOnBlur={false}
                >
                  {({ errors, touched, values, dirty, isValid }) => (
                    <Form>
                      <h1 className="step-title text-center">
                        {registerText[props.form].purchaseStepsText[1]}
                      </h1>
                      {Object.keys(errors).length > 0 ? (
                        <div className="errors">
                          <div className="col-1 p-0 error-logo" />
                          <ul className="p-0 errors-list">
                            <ErrorMessage
                              name="firstName"
                              component={() => (
                                <TextError
                                  wrapper="li"
                                  text={errors.firstName}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="lastName"
                              component={() => (
                                <TextError
                                  wrapper="li"
                                  text={errors.lastName}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="position"
                              component={() => (
                                <TextError
                                  wrapper="li"
                                  text={errors.position}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="school"
                              component={() => (
                                <TextError wrapper="li" text={errors.school} />
                              )}
                            />
                            <ErrorMessage
                              name="city"
                              component={() => (
                                <TextError wrapper="li" text={errors.city} />
                              )}
                            />
                            <ErrorMessage
                              name="country"
                              component={() => (
                                <TextError wrapper="li" text={errors.country} />
                              )}
                            />
                            <ErrorMessage
                              name="state"
                              component={() => (
                                <TextError wrapper="li" text={errors.state} />
                              )}
                            />
                            <ErrorMessage
                              name="zip"
                              component={() => (
                                <TextError wrapper="li" text={errors.zip} />
                              )}
                            />
                            <ErrorMessage
                              name="schoolPhone"
                              component={() => (
                                <TextError
                                  wrapper="li"
                                  text={errors.schoolPhone}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="schoolType"
                              component={() => (
                                <TextError
                                  wrapper="li"
                                  text={errors.schoolType}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="isTitleOne"
                              component={() => (
                                <TextError
                                  wrapper="li"
                                  text={errors.isTitleOne}
                                />
                              )}
                            />
                          </ul>
                        </div>
                      ) : null}
                      <div className="step-info text-center">
                        {registerText[props.form].schoolInfoSubTitle}
                        <br />
                        <br />
                      </div>
                      <div className="mx-0 my-3 row w-100">
                        <label
                          className="form-label col-form-label p-0"
                          htmlFor="firstName"
                        >
                          First Name
                          <span className="form-required"> *</span>
                        </label>
                        <Field
                          className={`form-control ${
                            errors.firstName && "is-invalid"
                          }`}
                          type="text"
                          name="firstName"
                          placeholder=""
                        />
                      </div>
                      <div className="mx-0 my-3 row w-100">
                        <label
                          className="form-label col-form-label p-0"
                          htmlFor="LastName"
                        >
                          Last Name
                          <span className="form-required"> *</span>
                        </label>
                        <Field
                          className={`form-control ${
                            errors.lastName && "is-invalid"
                          }`}
                          type="text"
                          name="lastName"
                          placeholder=""
                        />
                      </div>
                      <div className="mx-0 my-3 row w-100">
                        <label
                          className="form-label col-form-label p-0"
                          htmlFor="position"
                        >
                          Position
                          <span className="form-required"> *</span>
                        </label>
                        <Field
                          as="select"
                          className="form-control form-select"
                          name="position"
                        >
                          <option value="0">-Select-</option>
                          <option value="1">Teacher</option>
                          <option value="2">Principal</option>
                          <option value="3">Librarian</option>
                          <option value="4">Administrative</option>
                          <option value="5">Technology Coordinator</option>
                          <option value="6">Curriculum Coordinator</option>
                          <option value="7">Reading Specialist</option>
                          <option value="8">Bilingual/ESL Specialist</option>
                          <option value="9">Title 1 Coordinator</option>
                          <option value="10">Tutor/Instructional Coach</option>
                          <option value="11">
                            Parent or Other Family Member
                          </option>
                          <option value="12">College Student</option>
                          <option value="13">Homeschooler</option>
                          <option value="14">Paraprofessional Educator</option>
                          <option value="15">Other</option>
                        </Field>
                        <div className="description text-start pe-0">
                          Your position in the school
                        </div>
                      </div>
                      <BasicInfo
                        errors={errors}
                        values={values}
                        isValid={isValid}
                        dirty={dirty}
                        classes={"mx-0 my-3 row w-100"}
                      />
                      <div className="d-grid col-12 mx-auto mb-0">
                        <button
                          className="btn btn-submit btn-brandy-punch btn-spinner-indicator"
                          type="submit"
                          disabled={buttonDisabled}
                        >
                          {buttonText}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormSchoolInfo;
