import { Link } from "react-router-dom";
import Header from "../../../header/Header";

const UpgradeSubscriptionForm = () => {
  return (
    <div className="container p-4">
      <div className="col-sm-10 col-lg-4 offset-sm-1 offset-lg-2 mb-3">
        <Header
          steps={[]}
          activeStep={2}
          previousPath={"/teachers/dashboard"}
          buttonText="<< Back to Dashboard"
        />
      </div>

      <div className="options text-center">
        <h1 className="title">How Do You Want To Purchase?</h1>
        <p className="step">Choose one of these two options:</p>
        <p className="step">1. Pay now with a credit card or purchase order.</p>
        <p className="step">
          2. Send an automated email to notify your administrator requesting to
          purchase the service.
        </p>
      </div>
      <fieldset className="fs-password text-center mt-4">
        <legend className="legend-password">Select who is going to Pay</legend>
      </fieldset>
      <div className="buttons-container text-center mt-3">
        <Link to="/purchase/from-trial" className="btn btn-table-brandy-punch button-upgrade me-2">PAY NOW</Link>
        <Link to="/purchase/from-trial/mail" className="btn btn-table-brandy-punch button-upgrade send">SEND EMAIL</Link>
      </div>
    </div>
  );
};

export default UpgradeSubscriptionForm;