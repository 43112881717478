import { ErrorMessage, Field, Form, Formik, FormikValues } from "formik";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../../header/Header";
import TextError from "../../../TextError";
import * as Yup from "yup";
import { emailToBuyer } from "../../../../api/rifService";
import { useContext, useState } from "react";
import UserContext from "../../../../context/user";

const UpgradeSubscriptionEmailForm = () => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userContext.token}`,
    },
  };

  const schemaValidate = Yup.object({
    email: Yup.string()
      .required("Administrator Email field is required.")
      .max(128),
  });

  const handleSubmit = async (values: FormikValues) => {
    userContext.setFormProgress(50);
    setButtonDisabled(true);
    const response = await emailToBuyer(requestConfig, values.email);
    if (response) {
      navigate("/teachers/dashboard", { state: { successEmail: true } });
    } else {
      setEmailError(true);
    }
    userContext.setFormProgress(0);
    setButtonDisabled(false);
  };

  return (
    <div className="container p-4">
      <div className="col-sm-10 col-lg-4 offset-sm-1 offset-lg-2 mb-3">
        <Header
          steps={[]}
          activeStep={2}
          previousPath={"/teachers/dashboard"}
          buttonText="<< Back to Dashboard"
        />
      </div>

      <div className="options text-center">
        <h1 className="title mb-3">Enter the Purchaser’s Email Address:</h1>
        <p className="step">
          An automated email will be sent requesting to purchase the service for
          you.
        </p>
        <p className="step">Your roster data will remain after the purchase!</p>
      </div>
      <div className="text-center form-container">
        <Formik
          initialValues={{ email: "" }}
          validationSchema={schemaValidate}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ errors }) => (
            <Form>
              <div className="error-adm-mail my-3 mx-auto">
                <ErrorMessage
                  name="email"
                  component={() => (
                    <TextError wrapper="div" text={errors.email} />
                  )}
                />
              </div>
              {emailError && (
                <div className="w-50 m-auto my-3">
                  <TextError
                    wrapper="div"
                    text="There was an error, We were not able to send the email, please try again"
                  />
                </div>
              )}
              <div>
                <label className="adm-email" htmlFor="firstName">
                  Administrator Email
                  <span className="form-required me-2"> *</span>
                </label>
                <Field
                  className={`adm-field ${errors.email && "is-invalid"}`}
                  type="text"
                  name="email"
                  placeholder=""
                />
              </div>
              <div className="buttons-container text-center mt-3">
                <Link
                  to={`${buttonDisabled ? "#" : "/purchase/from-trial/how" }`}
                  className={`btn btn-table-mojo button-upgrade cancel ${buttonDisabled ? "btn-disabled" : ""}`}
                >
                  CANCEL
                </Link>
                <button
                  type="submit"
                  className="btn btn-table-brandy-punch button-upgrade send btn-spinner-indicator"
                  disabled={buttonDisabled}
                >
                  SEND EMAIL
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UpgradeSubscriptionEmailForm;
