import { useEffect, useState, useContext } from "react";
import UserContext from "../../context/user";

const PasswordForm = (props: {
  from: string;
  onSubmit: any;
  onPasswordChange: any;
  password: any;
  buttonState: any;
}) => {
  let placeholder = "";
  let formButtons;

  const handleSubmit = () => {
    if (props.from === "parents") {
      if (radio.classRadio1 === "radio-on") {
        props.onSubmit(icons);
      } else {
        props.onSubmit(inputTextValue);
      }
    } else if (props.from === "modal") {
      if (radio.classRadio1 === "radio-on") {
        props.onSubmit(icons);
      } else {
        props.onSubmit(inputTextValue);
      }
    }
  };

  if (props.from === "parents") {
    placeholder = "Enter Password";
    formButtons = (
      <button
        type="button"
        className="btn btn-brandy-punch mt-5 sign-in btn-spinner-indicator"
        onClick={handleSubmit}
        disabled={props.buttonState}
      >
        SIGN IN
      </button>
    );
  } else if (props.from === "modal") {
    formButtons = <></>;
  }

  // Handle fake radio element
  const [radio, setRadio] = useState({
    classRadio1: "radio-on",
    classRadio2: "radio-off",
  });

  useEffect(() => {
    if(props.password.passwordType !== "") {
      if (props.password.passwordType === "icon") {
        setInputTextValue("");
        if (props.password.password[1] !== undefined) {
          setIcons([props.password.password[0], props.password.password[1]])
          props.onPasswordChange({
            password: [props.password.password[0], props.password.password[1]],
            type: "icon",
          });
        } else {
          setIcons([props.password.password[0]])
          props.onPasswordChange({
            password: [props.password.password[0]],
            type: "icon",
          });
        }
        setRadio({
          classRadio1: "radio-on",
          classRadio2: "radio-off",
        });
      } else if (props.password.passwordType === "string") {
        setIcons([]);
        setInputTextValue(props.password.password);
        props.onPasswordChange({password: props.password.password, type: "text"})
        setRadio({
          classRadio1: "radio-off",
          classRadio2: "radio-on",
        });
      }
    }
  }, []);

  const handlerRadio = (radio: string) => {
    props.onPasswordChange({ password: "", type: "" });
    switch (radio) {
      case "radio-1":
        setInputTextValue("");
        setRadio({
          classRadio1: "radio-on",
          classRadio2: "radio-off",
        });
        break;
      case "radio-2":
        setIcons([]);
        setRadio({
          classRadio1: "radio-off",
          classRadio2: "radio-on",
        });
        break;
    }
  };

  // Set a password type
  let passwordType = "";

  if (radio.classRadio1 === "radio-on") {
    passwordType = "icon";
  } else if (radio.classRadio2 === "radio-on") {
    passwordType = "text";
  }

  // Clean passwords
  const [inputTextValue, setInputTextValue] = useState("");

  const handlerChangeText = (event: any) => {
    setInputTextValue(event.target.value);
    props.onPasswordChange({ password: event.target.value, type: "text" });
  };

  const handlerClear = (mode: any) => {
    switch (mode) {
      case "text":
        setInputTextValue("");
        props.onPasswordChange({
          password: "",
          type: ""
        });
        break;
      case "icon":
        setIcons([]);
        props.onPasswordChange({
          password: "",
          type: ""
        });
        break;
    }
  };

  // Render icons password
  const [icons, setIcons]: any = useState([]);

  const handlerIconPassword = (newIconClass: string) => {
    if (icons.length <= 1) {
      setIcons([...icons, newIconClass]);
      props.onPasswordChange({
        password: [...icons, newIconClass],
        type: "icon",
      });
    }
  };

  // Set random passwords
  const words = [
    "dog",
    "rocket",
    "star",
    "heart",
    "diamond",
    "book",
    "balloon",
    "house",
    "apple",
    "sneaker",
    "rainbow",
    "sun",
    "truck",
    "flow",
    "banana",
    "cat",
  ];
  let textPassword = "";

  const handlerRandomIconPassword = (newRandomIcons: any) => {
    setIcons([newRandomIcons[0], newRandomIcons[1]]);
  };

  const handlerAutoCreate = (mode: any) => {
    switch (mode) {
      case "text":
        randomTextPassword(words);
        break;
      case "icon":
        randomIconPassword();
        break;
    }
  };

  const randomTextPassword = (words: any) => {
    const item_a = words[Math.floor(Math.random() * words.length)];
    const item_b = words[Math.floor(Math.random() * words.length)];
    textPassword = `${item_a} ${item_b}`;
    setInputTextValue(textPassword);
    props.onPasswordChange({ password: textPassword, type: "text" });
  };

  const randomIconPassword = () => {
    let value1 = "";
    let value2 = "";
    value1 = Math.floor(Math.random() * 7 + 1).toString();
    value2 = Math.floor(Math.random() * 7 + 1).toString();
    handlerRandomIconPassword([`${value1}`, `${value2}`]);
    props.onPasswordChange({
      password: [`${value1}`, `${value2}`],
      type: "icon",
    });
  };

  // Set password type body
  let passwordTypeBody;

  if (radio.classRadio1 === "radio-on") {
    passwordTypeBody = (
      <div className="icons icons-wrapper">
        <div className="icons-selected">
          {icons.map((x: number, i: number) => (
            <span key={i} className={`icon value-${x} icon-selected`}></span>
          ))}
        </div>
        <div className="icons ">
          <span
            className="icon value-1"
            onClick={() => {
              handlerIconPassword("1");
            }}
          ></span>
          <span
            className="icon value-2"
            onClick={() => {
              handlerIconPassword("2");
            }}
          ></span>
          <span
            className="icon value-3"
            onClick={() => {
              handlerIconPassword("3");
            }}
          ></span>
          <span
            className="icon value-4"
            onClick={() => {
              handlerIconPassword("4");
            }}
          ></span>
          <span
            className="icon value-5"
            onClick={() => {
              handlerIconPassword("5");
            }}
          ></span>
          <span
            className="icon value-6"
            onClick={() => {
              handlerIconPassword("6");
            }}
          ></span>
          <span
            className="icon value-7"
            onClick={() => {
              handlerIconPassword("7");
            }}
          ></span>
          <span
            className="icon value-8"
            onClick={() => {
              handlerIconPassword("8");
            }}
          ></span>
        </div>
      </div>
    );
  } else {
    passwordTypeBody = (
      <div className="modal-form text-password-form">
        <div className="form-item">
          <input
            type="text"
            name="password"
            id="input-text-password"
            className="form-input text-password"
            onChange={handlerChangeText}
            value={inputTextValue}
            placeholder={placeholder}
          ></input>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="password-type">
        <span
          className={`fake-radio ${radio.classRadio1}`}
          onClick={() => handlerRadio("radio-1")}
        >
          Icon Password
        </span>
        <span
          className={`fake-radio ${radio.classRadio2}`}
          onClick={() => handlerRadio("radio-2")}
        >
          Text Password
        </span>

        <div className="password-buttons-wrapper">
          <button
            className="btn btn-modal btn-brandy-punch clear-button"
            type="button"
            onClick={() => handlerClear(passwordType)}
          >
            Clear
          </button>
          {props.from === "modal" && (
            <button
              type="button"
              className="btn btn-modal btn-brandy-punch auto-create-button"
              onClick={() => handlerAutoCreate(passwordType)}
            >
              Auto Create
            </button>
          )}
        </div>
      </div>

      <div className="password-render">{passwordTypeBody}</div>
      {formButtons}
    </>
  );
};

export default PasswordForm;
