import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../Parents/Header";

const ChangePasswordForm = (props: {
  onSubmit: (arg0: string | object, arg1: string | object) => void;
  classHash: string;
  studentToken: string | undefined;
  studentsData: any;
}) => {
  // Handle fake radio element
  const [radioOld, setRadioOld] = useState({
    classRadio1: "radio-on",
    classRadio2: "radio-off",
  });

  const handlerRadioOld = (radio: string) => {
    switch (radio) {
      case "radio-1":
        setPasswords((prevPassword) => ({
          oldPassword: "",
          newPassword: prevPassword.newPassword,
        }));
        setRadioOld({
          classRadio1: "radio-on",
          classRadio2: "radio-off",
        });
        setIsValid((prevIsValid) => ({
          oldPassword: false,
          newPassword: prevIsValid.newPassword,
        }));
        break;
      case "radio-2":
        setIcons((prevIcons) => ({
          oldPassword: [],
          newPassword: prevIcons.newPassword,
        }));
        setRadioOld({
          classRadio1: "radio-off",
          classRadio2: "radio-on",
        });
        setIsValid((prevIsValid) => ({
          oldPassword: false,
          newPassword: prevIsValid.newPassword,
        }));
        break;
    }
  };

  const [radioNew, setRadioNew] = useState({
    classRadio1: "radio-on",
    classRadio2: "radio-off",
  });

  const handlerRadioNew = (radio: string) => {
    switch (radio) {
      case "radio-1":
        setPasswords((prevPassword) => ({
          oldPassword: prevPassword.oldPassword,
          newPassword: "",
        }));
        setRadioNew({
          classRadio1: "radio-on",
          classRadio2: "radio-off",
        });
        setIsValid((prevIsValid) => ({
          oldPassword: prevIsValid.newPassword,
          newPassword: false,
        }));
        break;
      case "radio-2":
        setIcons((prevIcons) => ({
          oldPassword: prevIcons.oldPassword,
          newPassword: [],
        }));
        setRadioNew({
          classRadio1: "radio-off",
          classRadio2: "radio-on",
        });
        setIsValid((prevIsValid) => ({
          oldPassword: prevIsValid.oldPassword,
          newPassword: false,
        }));
        break;
    }
  };

  // Set a password type
  let passwordTypeOld = "";
  let passwordTypeNew = "";

  if (radioOld.classRadio1 === "radio-on") {
    passwordTypeOld = "icon";
  } else if (radioOld.classRadio2 === "radio-on") passwordTypeOld = "text";

  if (radioNew.classRadio1 === "radio-on") {
    passwordTypeNew = "icon";
  } else if (radioNew.classRadio2 === "radio-on") passwordTypeNew = "text";

  // Clean passwords
  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
  });

  const handlerChangeText = (event: any) => {
    if (event.target.name === "oldPassword") {
      setPasswords((prevPassword) => ({
        oldPassword: event.target.value,
        newPassword: prevPassword.newPassword,
      }));
    } else if (event.target.name === "newPassword") {
      setPasswords((prevPassword) => ({
        oldPassword: prevPassword.oldPassword,
        newPassword: event.target.value,
      }));
    }
  };

  const handleSubmit = () => {
    let oldPassword: string | object;
    let newPassword: string | object;

    if (radioOld.classRadio1 === "radio-on") {
      oldPassword = {
        clean_pass: icons.oldPassword[0],
        pass_type: "icon",
      };
      if  (icons.oldPassword.length > 1) {
        oldPassword.clean_pass = icons.oldPassword[0] + icons.oldPassword[1];
      }
    } else {
      oldPassword = {
        clean_pass: passwords.oldPassword,
        pass_type: "text",
      };
    }

    if (radioNew.classRadio1 === "radio-on") {
      newPassword = {
        clean_pass: icons.newPassword[0],
        pass_type: "icon",
      };
      if  (icons.newPassword.length > 1) {
        newPassword.clean_pass = icons.newPassword[0] + icons.newPassword[1];
      }
    } else {
      newPassword = {
        clean_pass: passwords.newPassword,
        pass_type: "text",
      };
    }

    props.onSubmit(oldPassword, newPassword);
  };

  const handlerClear = (mode: any, password: any) => {
    if (password === "oldPassword") {
      switch (mode) {
        case "text":
          setPasswords((prevPassword) => ({
            oldPassword: "",
            newPassword: prevPassword.newPassword,
          }));
          break;
        case "icon":
          setIcons((prevIcons) => ({
            oldPassword: [],
            newPassword: prevIcons.newPassword,
          }));
          break;
      }
    } else {
      switch (mode) {
        case "text":
          setPasswords((prevPassword) => ({
            oldPassword: prevPassword.oldPassword,
            newPassword: "",
          }));
          break;
        case "icon":
          setIcons((prevIcons) => ({
            oldPassword: prevIcons.oldPassword,
            newPassword: [],
          }));
          break;
      }
    }
  };

  // Render icons password
  const [icons, setIcons] = useState({
    oldPassword: [],
    newPassword: [],
  });

  const handlerIconPassword = (newIconClass: string, from: string) => {
    if (from === "newPassword") {
      if (icons.newPassword.length <= 1) {
        setIcons({
          oldPassword: [...icons.oldPassword],
          newPassword: [...icons.newPassword, newIconClass],
        });
      }
    } else {
      if (icons.oldPassword.length <= 1) {
        setIcons({
          oldPassword: [...icons.oldPassword, newIconClass],
          newPassword: [...icons.newPassword],
        });
      }
    }
  };

  const [isValid, setIsValid] = useState({
    oldPassword: false,
    newPassword: false,
  });

  useEffect(() => {
    if (radioOld.classRadio1 === "radio-on") {
      if (icons.oldPassword.length > 0) {
        setIsValid((prevIsValid) => ({
          oldPassword: true,
          newPassword: prevIsValid.newPassword,
        }));
      } else {
        setIsValid((prevIsValid) => ({
          oldPassword: false,
          newPassword: prevIsValid.newPassword,
        }));
      }
    } else {
      if (passwords.oldPassword.length >= 2) {
        setIsValid((prevIsValid) => ({
          oldPassword: true,
          newPassword: prevIsValid.newPassword,
        }));
      } else {
        setIsValid((prevIsValid) => ({
          oldPassword: false,
          newPassword: prevIsValid.newPassword,
        }));
      }
    }

    if (radioNew.classRadio1 === "radio-on") {
      if (icons.newPassword.length > 0) {
        setIsValid((prevIsValid) => ({
          oldPassword: prevIsValid.oldPassword,
          newPassword: true,
        }));
      } else {
        setIsValid((prevIsValid) => ({
          oldPassword: prevIsValid.oldPassword,
          newPassword: false,
        }));
      }
    } else {
      if (passwords.newPassword.length >= 2) {
        setIsValid((prevIsValid) => ({
          oldPassword: prevIsValid.oldPassword,
          newPassword: true,
        }));
      } else {
        setIsValid((prevIsValid) => ({
          oldPassword: prevIsValid.oldPassword,
          newPassword: false,
        }));
      }
    }
  }, [icons, passwords]);

  // Set password type body
  let passwordTypeBodyOld;

  if (radioOld.classRadio1 === "radio-on") {
    passwordTypeBodyOld = (
      <div className="icons icons-wrapper">
        <div className="icons-selected">
          {icons.oldPassword.map((x, i) => (
            <span key={i} className={`icon value-${x} icon-selected`}></span>
          ))}
        </div>
        <div className="icons ">
          <span
            className="icon value-1"
            onClick={() => {
              handlerIconPassword("1", "oldPassword");
            }}
          ></span>
          <span
            className="icon value-2"
            onClick={() => {
              handlerIconPassword("2", "oldPassword");
            }}
          ></span>
          <span
            className="icon value-3"
            onClick={() => {
              handlerIconPassword("3", "oldPassword");
            }}
          ></span>
          <span
            className="icon value-4"
            onClick={() => {
              handlerIconPassword("4", "oldPassword");
            }}
          ></span>
          <span
            className="icon value-5"
            onClick={() => {
              handlerIconPassword("5", "oldPassword");
            }}
          ></span>
          <span
            className="icon value-6"
            onClick={() => {
              handlerIconPassword("6", "oldPassword");
            }}
          ></span>
          <span
            className="icon value-7"
            onClick={() => {
              handlerIconPassword("7", "oldPassword");
            }}
          ></span>
          <span
            className="icon value-8"
            onClick={() => {
              handlerIconPassword("8", "oldPassword");
            }}
          ></span>
        </div>
      </div>
    );
  } else {
    passwordTypeBodyOld = (
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        <Form className="modal-form text-password-form">
          <div className="form-item">
            <Field
              type="text"
              name="oldPassword"
              id="input-text-old-password"
              className="form-input text-password"
              onChange={handlerChangeText}
              value={passwords.oldPassword}
              placeholder="Enter Password"
            ></Field>
          </div>
        </Form>
      </Formik>
    );
  }

  let passwordTypeBodyNew;

  if (radioNew.classRadio1 === "radio-on") {
    passwordTypeBodyNew = (
      <div className="icons icons-wrapper">
        <div className="icons-selected">
          {icons.newPassword.map((x, i) => (
            <span key={i} className={`icon value-${x} icon-selected`}></span>
          ))}
        </div>
        <div className="icons ">
          <span
            className="icon value-1"
            onClick={() => {
              handlerIconPassword("1", "newPassword");
            }}
          ></span>
          <span
            className="icon value-2"
            onClick={() => {
              handlerIconPassword("2", "newPassword");
            }}
          ></span>
          <span
            className="icon value-3"
            onClick={() => {
              handlerIconPassword("3", "newPassword");
            }}
          ></span>
          <span
            className="icon value-4"
            onClick={() => {
              handlerIconPassword("4", "newPassword");
            }}
          ></span>
          <span
            className="icon value-5"
            onClick={() => {
              handlerIconPassword("5", "newPassword");
            }}
          ></span>
          <span
            className="icon value-6"
            onClick={() => {
              handlerIconPassword("6", "newPassword");
            }}
          ></span>
          <span
            className="icon value-7"
            onClick={() => {
              handlerIconPassword("7", "newPassword");
            }}
          ></span>
          <span
            className="icon value-8"
            onClick={() => {
              handlerIconPassword("8", "newPassword");
            }}
          ></span>
        </div>
      </div>
    );
  } else {
    passwordTypeBodyNew = (
      <Formik initialValues={{}} onSubmit={() => {}}>
        <Form className="modal-form text-password-form">
          <div className="form-item">
            <Field
              type="text"
              name="newPassword"
              id="input-text-new-password"
              className="form-input text-password"
              onChange={handlerChangeText}
              value={passwords.newPassword}
              placeholder="Enter Password"
            ></Field>
          </div>
        </Form>
      </Formik>
    );
  }

  let teacher: any = null;

  if (props.studentsData !== undefined) {
    if (props.studentsData.userType === "teacher") {
      teacher = { teacher: true };
    } else if (props.studentsData.userType === "student") {
      teacher = { access: true };
    }
  }

  return (
    <div className="parents-container change-password">
      <Header title="Change Your Password" subTitle="" from={props.studentsData.userType}>
        {" "}
      </Header>
      <div className="link-container">
        <Link
          to={`/sigin/classroom/${props.classHash}`}
          className="back-to-button"
          state={teacher}
        >{`<< Back to Students`}</Link>
      </div>
      <div className="parents-change-password text-center">
        <fieldset className="fs-password">
          <legend className="legend-password">Old Password</legend>
        </fieldset>
        <>
          <div className="password-type">
            <span
              className={`fake-radio ${radioOld.classRadio1}`}
              onClick={() => handlerRadioOld("radio-1")}
            >
              Icon Password
            </span>
            <span
              className={`fake-radio ${radioOld.classRadio2}`}
              onClick={() => handlerRadioOld("radio-2")}
            >
              Text Password
            </span>

            <div className="password-buttons-wrapper">
              <button
                className="btn btn-modal btn-brandy-punch clear-button"
                type="button"
                onClick={() => handlerClear(passwordTypeOld, "oldPassword")}
              >
                Clear
              </button>
            </div>
          </div>

          <div className="password-render">{passwordTypeBodyOld}</div>
        </>

        <fieldset className="fs-password mt-3">
          <legend className="legend-password">New Password</legend>
        </fieldset>
        <>
          <div className="password-type">
            <span
              className={`fake-radio ${radioNew.classRadio1}`}
              onClick={() => handlerRadioNew("radio-1")}
            >
              Icon Password
            </span>
            <span
              className={`fake-radio ${radioNew.classRadio2}`}
              onClick={() => handlerRadioNew("radio-2")}
            >
              Text Password
            </span>

            <div className="password-buttons-wrapper">
              <button
                className="btn btn-modal btn-brandy-punch clear-button"
                type="button"
                onClick={() => handlerClear(passwordTypeNew, "newPassword")}
              >
                Clear
              </button>
            </div>
          </div>

          <div className="password-render">{passwordTypeBodyNew}</div>
        </>

        <div className="forgot-password-buttons mt-5">
          <Link
            to={`/sigin/login/${props.studentToken}`}
            state={{ studentsInfo: props.studentsData }}
            className="btn btn-mojo"
          >
            CANCEL
          </Link>
          <button
            type="button"
            className="ms-2 btn btn-brandy-punch btn-spinner-indicator"
            onClick={handleSubmit}
            disabled={!(isValid.newPassword && isValid.oldPassword)}
          >
            CHANGE PASSWORD
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordForm;
