import { redeemCleverGift, validateGiftToken } from "@api/rifService";
import SubmitButton from "@components/Buttons/SubmitButton";
import TextError from "@components/TextError";
import CleverContext from "@context/clever";
import UserContext from "@context/user";
import Dashboard from "@pages/dashboard/Dashboard";
import GiftSuccessBox from "@pages/gift/Redeem/GiftSuccessBox";
import { registerText, SKYBRARY_MAILTO } from "@utils/constants";
import { redeemEduGiftCodeEvent } from "@utils/events/googleAnalitycs";
import { ErrorMessage, Field, Form, Formik, FormikValues } from "formik";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

const CleverRedeemForm = (props: { page: string }) => {
  const navigate = useNavigate();

  const schemaValidate = Yup.object({
    giftCode: Yup.string()
      .required("Subscription Code field is required.")
      .max(128),
  });

  const params = useParams();

  let giftCode: string = "";

  //This loaded only if the gift code is already set in the URL
  if (params.giftToken !== undefined) {
    giftCode = params.giftToken;
  }

  //Variables that handle error messages
  const [isTokenUsed, setIsTokenUsed] = useState(false);

  const userContext = useContext(UserContext);
  const cleverContext = useContext(CleverContext);

  const [giftSuccess, setGiftSuccess] = useState(false);
  const handleReload = () => {
    setGiftSuccess(false);
  };

  let location = useLocation();
  const pageUrl = location.pathname;

  const handleSubmit = async (values: FormikValues) => {
    userContext.setFormProgress(50);
    values.giftCode = values.giftCode.trim();
    const validateResponse = await validateGiftToken(values.giftCode);
    userContext.setFormProgress(100);

    if (!validateResponse.status) {
      setIsTokenUsed(true);
      return;
    } else {
      setIsTokenUsed(false);
    }

    if (validateResponse.gift["isClever"]) {
      redeemEduGiftCodeEvent(pageUrl);
      cleverContext.saveCleverGiftToken(values.giftCode);
      const accountData = {
        token: values.giftCode,
        newAccount: false,
        subscriptionsRenewals: [],
      };
      await redeemCleverGift(userContext.token, accountData);

      setGiftSuccess(true);
    }
  };

  useEffect(() => {
    document.title = "Subscription Code | Reading Is Fundamental";
  }, []);

  const invalidRedeemCodeError = (
    <div>
      You have used an invalid, expired or previously redeemed gift code. Please
      contact us on {SKYBRARY_MAILTO} or 1 (877) RIF-READ for support.
    </div>
  );

  return (
    <Dashboard rol="admin">
      <div className="district-subscriptions-wrapper text-center">
        {giftSuccess ? (
          <GiftSuccessBox onReload={handleReload} />
        ) : (
          <div className="register-form mx-auto">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="login-form">
                    <Formik
                      initialValues={{
                        giftCode: giftCode,
                      }}
                      validationSchema={schemaValidate}
                      onSubmit={handleSubmit}
                      validateOnChange={false}
                      validateOnBlur={false}
                    >
                      {({ errors }) => (
                        <Form>
                          {isTokenUsed && (
                            <TextError
                              wrapper="div"
                              text={invalidRedeemCodeError}
                            />
                          )}
                          <h1 className="step-title title-gift">
                            Redeem Your Subscription
                          </h1>
                          <div className="gift full-box-information">
                            <p>
                              Enter the subscription code from your email here.
                            </p>
                            {Object.keys(errors).length > 0 && (
                              <div className="errors">
                                <div className="col-1 p-0 error-logo" />
                                <ul className="p-0 errors-list">
                                  <ErrorMessage
                                    name="giftCode"
                                    component={() => (
                                      <TextError
                                        wrapper="li"
                                        text={errors.giftCode}
                                      />
                                    )}
                                  />
                                </ul>
                              </div>
                            )}
                            <div className="step-info">
                              {registerText[props.page].subTitleCode}
                            </div>
                            <div className="mx-0 my-3 row w-100 input-box">
                              <label
                                className="form-label col-form-label text-end p-0"
                                htmlFor="giftCode"
                              >
                                Subscription Code
                                <span className="form-required"> *</span>
                              </label>
                              <Field
                                className={`form-control gift-code-input ${errors.giftCode && "is-invalid"}`}
                                type="text"
                                name="giftCode"
                              />
                            </div>
                            <div className="row button-redeem">
                              <div className="col-6 mx-auto mb-0 px-0">
                                <SubmitButton isClever={true} text="Submit" />
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <button
          className="button btn-sky btn-moderate-green btn-sky-lg"
          onClick={() => navigate("/adm/dashboard")}
        >
          return to dashboard
        </button>
      </div>
    </Dashboard>
  );
};

export default CleverRedeemForm;
